// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toPascalCase from 'to-pascal-case';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import Aside from 'components/page/Aside';
import Content from 'components/page/Content';
import SideMenu from 'components/menu/SideMenu';
import Breadcrumb from 'components/menu/Breadcrumb';
import { renderReferences } from 'containers/page/shared/References';
import { grammarMenuItems, panels } from 'constants/page';
import { Heading, Wrapper } from 'styles/content';
import * as actions from 'actions/course';

const Link = styled.a`
  display: block;
  font-size: 1.6rem;
  margin: 1rem 0;
`;

const GrammarPage = ({
  courseName = '',
  courseContent,
}: {
  courseName?: string,
  courseContent: {},
}) => {
  const breadcrumbLinks = [
    { name: 'Home', link: '/' },
    { name: 'Grammar', link: '/grammar' },
    {
      name: toPascalCase(courseName),
      link: `/grammar/${courseName}`,
      isActive: true,
    },
  ];
  return (
    <div className="ui grid">
      <Aside size="three">
        <SideMenu menuItems={grammarMenuItems} />
      </Aside>
      {!isEmpty(courseName) ? (
        <Content
          courseName={courseName}
          courseType="grammar"
          content={courseContent}
          breadcrumbLinks={breadcrumbLinks}
        />
      ) : (
        <Wrapper>
          <Heading>
            <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
          </Heading>
          <Wrapper>
            <h2 className="ui header">Grammar</h2>
            <p>
              Here are the most important German grammar
              rules:
            </p>
            <Link href="/grammar/adjective">Adjective</Link>
            <Link href="/grammar/conjugation">
              Conjugation
            </Link>
            <Link href="/grammar/dative">Dative</Link>
            <Link href="/grammar/future">Future</Link>
            <Link href="/grammar/genitive">Genitive</Link>
            <Link href="/grammar/conjugation">
              Conjugation
            </Link>
            <Link href="/grammar/passive">Passive</Link>
            <Link href="/grammar/plural">Plural</Link>
            <Link href="/grammar/perfect">Perfect</Link>
            <Link href="/grammar/present">Present</Link>
            <Link href="/grammar/preposition">
              Preposition
            </Link>
            <Link href="/grammar/preterite">Preterite</Link>
            <Link href="/grammar/pronoun">Pronoun</Link>
          </Wrapper>
        </Wrapper>
      )}
      <Aside size="five" side="right">
        {renderReferences(panels)}
      </Aside>
    </div>
  );
};

export default connect(
  state => state,
  dispatch => bindActionCreators(actions, dispatch)
)(GrammarPage);
