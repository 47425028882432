// @flow
import 'isomorphic-fetch';
import { ADDRESS } from 'constants/config';
import processResponse from './helper';

const getCourse = (
  courseType: string,
  courseName: string
) => {
  return fetch(`${ADDRESS}/${courseType}/${courseName}`, {
    method: 'get',
  }).then(response => {
    return processResponse(response);
  });
};

export default getCourse;
