import React from 'react';

const Interrogatives = () => (
  <table className="ui celled table">
    <thead>
      <tr>
        <th>Question</th>
        <th>Meaning</th>
        <th>Question</th>
        <th>Meaning</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>Wo?</b></td>
        <td>Where?</td>
        <td><b>Was?</b></td>
        <td>What?</td>
      </tr>
      <tr>
        <td><b>Wann?</b></td>
        <td>When?</td>
        <td><b>Wer?</b></td>
        <td>Who?</td>
      </tr>
      <tr>
        <td><b>Wie?</b></td>
        <td>How?</td>
        <td><b>Warum?</b></td>
        <td>Why?</td>
      </tr>
      <tr>
        <td><b>Wofür?</b></td>
        <td>For what?</td>
        <td><b>Worüber?</b></td>
        <td>Why?</td>
      </tr>
      <tr>
        <td><b>Wessen?</b></td>
        <td>Whose?</td>
        <td><b>Wem?</b></td>
        <td>To whom?</td>
      </tr>
      <tr>
        <td><b>Wohin?</b></td>
        <td>Where to?</td>
        <td><b>Woher?</b></td>
        <td>Where from?</td>
      </tr>
      <tr>
        <td><b>Wen?</b></td>
        <td>Whom?</td>
        <td><b>Wessen?</b></td>
        <td>Whose?</td>
      </tr>
      <tr>
        <td><b>Womit?</b></td>
        <td>Whith what?</td>
        <td><b>Worauf?</b></td>
        <td>On what?</td>
      </tr>
      <tr>
        <td><b>Wodurch?</b></td>
        <td>Through what?</td>
        <td><b>&nbsp;</b></td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
);

export default Interrogatives;
