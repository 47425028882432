import React from 'react';
import { Description } from 'styles/content';

const Preterite = () => (
  <Description>
    <h2 className="ui header">Präteritum</h2>
    <p>
      For an action in the past, there are basically 2
      options in German, the <b>Präteritum</b> and the{' '}
      <b>Perfekt</b>.
    </p>
    <p>
      The <b>Präteritum</b> is used:
    </p>
    <ul>
      <li>
        Together with <b>auxiliary verbs</b> (eg: sein,
        haben, werden) and with <b>modal verbs</b> (eg:
        können)
      </li>
      <li>In the written language</li>
      <li>Common in northern Germany</li>
    </ul>
  </Description>
);

export default Preterite;
