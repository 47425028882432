import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';

const Wrapper = styled.span`
  margin-top: 12rem;

  .off {
    opacity: 0.4;
  }
`;

const Char = styled.span`
  margin: 0 1rem;
`;

const Key = styled.span`
  border: 0.1rem solid ${COLORS.ALUMINIUM};
  border-radius: 0.4rem;
  color: ${COLORS.ALUMINIUM};
  display: inline-block;
  margin: 0.5rem;
  padding: 0.2rem 0.8rem;
`;

const CharKey = ({
  char,
  charKey,
}: {
  char: string,
  charKey: string,
}) => (
  <Wrapper>
    <Char>{char}</Char>
    <Key>{charKey}</Key>
  </Wrapper>
);

export default CharKey;
