import React from 'react';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Adjective = () => (
  <Description>
    <h2 className="ui header">
      Adjective <b>(Adjektiv)</b>
    </h2>
    <p>
      Adjectives come before the noun, Just like in in
      English, and are not capitalised. However, they are
      generally inflected when they come before a noun: they
      take an ending that depends on the gender and case of
      the noun phrase.
    </p>
    <p>
      As in English, the <b>predictive adjective</b>, an
      adjective that comes straight after the verbs{' '}
      <b>
        <i>sein</i>
      </b>{' '}
      and{' '}
      <b>
        <i>werden</i>
      </b>
      , is <b>invariable</b> in German. This is the case in
      singular:
    </p>
    <Example>
      <Examples>
        <p>
          Das Bild ist <b>schön</b>.
        </p>
        <Translation>The painting is beautiful</Translation>
      </Examples>
    </Example>
    <h3 className="ui header">
      Adjectival endings following a definite article
    </h3>
    <p>
      Just as the endings of articles change, so do those of
      adjectives.
    </p>
    <p>
      When an adjective follows a definite article (der,
      die, das), it is declined as follows:
    </p>
    <Example>
      <Examples>
        <p>
          Die Jung<b>e</b> Frau.
        </p>
        <Translation>The young woman.</Translation>
        <p>
          Der lustig<b>e</b> Mann.
        </p>
        <Translation>The funny man.</Translation>
      </Examples>
    </Example>
    <p>
      However, in the <b>plural</b> and in the{' '}
      <b>accusative masculaine</b> case, adjectives
      following a definite article take <b>-en</b>.
    </p>
    <Example>
      <Examples>
        <p>
          Die Rot<b>en</b> Autos.
        </p>
        <Translation>The red cars.</Translation>
        <p>
          Ich kaufe den schwart<b>en</b> Stuhl.
        </p>
        <Translation>
          I am buying the black chair.
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">
      Adjectival endings following an indefinite article
    </h3>
    <p>
      Adjectives that follow an <b>indefinite</b> article in
      the <b>accusative</b> (einen) also take the marker of
      the article: masculine adjectives therefore end in{' '}
      <b>-en</b>.
    </p>
    <Example>
      <Examples>
        <p>
          Ich esse d<b>en</b> grün<b>en</b> Chili.
        </p>
        <Translation>
          I am eating the green chilli.
        </Translation>
      </Examples>
    </Example>
    <p>
      The indefinite article <i>ein</i> does not have a
      plural form, so no indefinite article is used in the
      plural. Adjectives in the plural then end in<b>-e</b>:
    </p>
    <Example>
      <Examples>
        <p>
          Groß<b>e</b> Häuser.
        </p>
        <Translation>Bigger houses.</Translation>
        <p>
          Grün<b>e</b> Bäume.
        </p>
        <Translation>Green trees</Translation>
      </Examples>
    </Example>
    <p>
      When adjective follows a{' '}
      <b>singular negative indefinite article</b> (kein,
      keine), it also takes the marker of the definite
      article:<b>-er, -e or -es</b>:
    </p>
    <Example>
      <Examples>
        <p>
          D<b>er</b> Lehrer ist nicht nett - kein netter
          Lehrer
        </p>
        <p>
          D<b>ie</b> Frau ist nicht nett - keine nette Frau
        </p>
        <p>
          D<b>as</b> ist nicht nett - keine nettes Kind
        </p>
      </Examples>
    </Example>
    <p>
      The indefinite article <i>kein</i> has a plural form:{' '}
      <b>keine</b>. When an adjective follows <i>keine</i>,
      it ends with <b>-en</b>:
    </p>
    <Example>
      <Examples>
        <p>
          <b>keine</b> groß<b>en</b> Häuser
        </p>
        <Translation>No big houses</Translation>
        <p>
          <b>keine</b> grün<b>en</b> Bäume
        </p>
        <Translation>No green trees</Translation>
      </Examples>
    </Example>
    <h3 className="ui header">Adjectives in the dative</h3>
    <p>
      Adjectives in the dative end in <b>-en</b>:
    </p>
    <Example>
      <Examples>
        <p>
          Hier ist ein Blumenstrauß mit einer groß<b>en</b>{' '}
          Karte für dich
        </p>
        <Translation>
          Here is a bunch of flowers with a big card for you
        </Translation>
        <p>
          Er kommt von meinem neu<b>en</b> Freund
        </p>
        <Translation>
          It is from my new boyfriend
        </Translation>
      </Examples>
    </Example>
    <p>
      This marker is the same for all genders and the
      plural:
    </p>
    <Example>
      <Examples>
        <p>
          Sie bliebt bei den freundlich<b>en</b> Nachbarn
        </p>
        <Translation>
          She is staying with the friendly neighbours
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">Comparatives</h3>
    <p>
      To form comparative adjectives, e.g. bigger than/more
      beautiful than, you add <b>-er</b>: to the end of the
      adjective:
    </p>
    <Example>
      <Examples>
        <p>
          Stuttgart ist klein<b>er</b> als Berlin
        </p>
        <Translation>
          Stuttgart is smaller than Berlin
        </Translation>
      </Examples>
    </Example>
    <p>
      To compare two items you always use<b>als</b>:
    </p>
    <Example>
      <Examples>
        <p>
          Berlin is größ<b>er</b> als Stuttgart
        </p>
        <Translation>
          Berlin is bigger than Stuttgart
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">Less ... than</h3>
    <p>
      To say something is <i>less ... than</i> something
      else, there are two possible ways:
      <i>weniger</i> + adjective + <b>als</b> or{' '}
      <b>nicht so</b> + adjective + <i>wie</i>.
    </p>
    <Example>
      <Examples>
        <p>
          Sie ist <b>weniger stark als</b> ihre Mutter
        </p>
        <Translation>
          She is <b>less strong than</b> her mother
        </Translation>
        <p>
          Mein Sprung ist <b>nicht so hoch</b> wie deiner
        </p>
        <Translation>
          My jump is <b>not as high as</b> yours
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">As ... as</h3>
    <p>
      To say that something is <i>as ... as</i> something
      else, you use:
      <b>so</b> + adjective + <i>wie</i> ...
    </p>
    <Example>
      <Examples>
        <p>
          Augsberg ist <b>so</b> schön <b>wie</b> München
        </p>
        <Translation>
          Augsberg is <b>as</b> beautiful <b>as</b> Munich
        </Translation>
        <p>
          Lars ist <b>so</b> groß <b>wie</b> Michael
        </p>
        <Translation>
          Lars ist <b>as</b> tall <b>as</b> Michael
        </Translation>
        <p>
          As with other comparatives, the adjective here is
          invariable
        </p>
      </Examples>
    </Example>
    <h3 className="ui header">Superlatives</h3>
    <p>
      The superlative (this most ...) is used when more than
      two elements are compared. You use the ending{' '}
      <b>-ste</b> to form it:
    </p>
    <Example>
      <Examples>
        <p>
          Er war das nette<b>ste</b> Kind der Schule
        </p>
        <Translation>
          He was the nicest child in the school
        </Translation>
        <p>
          Er war der ruhig<b>ste</b> Schüler der Klasse
        </p>
        <Translation>
          He was the quietest pupil in the class
        </Translation>
      </Examples>
    </Example>
    <p>
      Superlative adverbs are formed using am + <b>-sten</b>
      :
    </p>
    <Example>
      <Examples>
        <p>
          Marcus fährt <b>am</b> schnell<b>sten</b>.
        </p>
        <Translation>Marcus drives the fastest</Translation>
        <p>
          Heidi spielt <b>am besten</b> Klavier
        </p>
        <Translation>
          Heidi plays the piano the best
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">
      Adjectives in the accusative
    </h3>
    <p>
      Just like with the definite article, the only
      adjective ending that changes in the{' '}
      <i>definite accusative case </i> is the{' '}
      <b>masculine</b>.
    </p>
    <Example>
      <Examples>
        <p>
          Sie trägt den blau<b>en</b> Rock
        </p>
        <Translation>
          She is wearing the blue skirt
        </Translation>
      </Examples>
    </Example>
    <p>
      The feminine, neuter and plural stay as the same in
      the nominitive case:
    </p>
    <Example>
      <Examples>
        <p>
          Ich mag die schwarz<b>e</b> Hose
        </p>
        <Translation>I like the black trousers</Translation>
        <p>
          Sie kauft das weiß<b>e</b> T-Shirt
        </p>
        <Translation>
          She is buying the white T-shirt
        </Translation>
        <p>
          Siehst du die teuer<b>en</b> Schuhe?
        </p>
        <Translation>
          Do you see the expensive shoes?
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">Adverbs</h3>
    <p>
      Adverbs of manner are used with verbs, adjectives or
      other adverbs and describe <b>how</b> something
      happens
    </p>
    <Example>
      <Examples>
        <p>
          Sie singt <b>schön</b>
        </p>
        <Translation>She sings beautifully</Translation>
      </Examples>
    </Example>
    <p>
      Unlike adjectives, German adverbs{' '}
      <span data-tooltip="How does she sing? - Beautifully">
        <u>never</u>
      </span>{' '}
      change their form
    </p>
    <Example>
      <Examples>
        <p>
          Er läuft <b>schnell</b>. Sie läuft auch{' '}
          <b>schnell</b>
        </p>
        <Translation>
          He is walking fast. She is also walking fast
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">Word order in questions</h3>
    <p>
      In questions the word order is <b>reversed</b>. You
      change the position of the subject and the verb or
      modal verb, but the position of the adverb does not
      change
    </p>
    <Example>
      <Examples>
        <p>
          <b>Wir gehen</b> schnell - <b>Gehen wir</b>{' '}
          schnell?
        </p>
        <Translation>
          We are walking fast - Are we walking fast?
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">
      The adjectives viel(e) & wenig(e)
    </h3>
    <p>
      As in English, German has countable nouns (e.g. eggs,
      apples) and uncountable nouns (e.g. flour, sugar).
      With countable nouns you use <i>viele</i> (many) and{' '}
      <i>wenige</i>
      to express an amount.
    </p>
    <Example>
      <Examples>
        <p>
          Wir brauchen <b>viele</b> Äpfel und <b>wenige</b>{' '}
          Eier
        </p>
        <Translation>
          We need a lot of apple, but not many eggs
        </Translation>
      </Examples>
    </Example>
    <p>
      With uncountable nouns you drop the <b>e</b> and use{' '}
      <i>viel</i> and <i>wenig</i>
    </p>
    <Example>
      <Examples>
        <p>
          Wir brauchen <b>viel</b> Zucker und <b>wenig</b>{' '}
          Butter
        </p>
        <Translation>
          We need a lot of sugar, but not much butter
        </Translation>
      </Examples>
    </Example>
    <h3 className="ui header">
      <i>Ein bisschen</i> and <i>ein paar</i>
    </h3>
    <p>
      To express <i>some</i>, you use different words for
      countable and uncountable nouns.
    </p>
    <Example>
      <Examples>
        <p>
          <b>Ein paar</b> Eier sind kaputt
        </p>
        <Translation>Some eggs are broken</Translation>
      </Examples>
      <Examples>
        <p>
          Wir brauchen <b>ein bisschen</b> Mehl
        </p>
        <Translation>We need some flour</Translation>
      </Examples>
    </Example>
  </Description>
);

export default Adjective;
