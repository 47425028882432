import ReactGA from 'react-ga';

export function init() {
  const isGAEnabled = process.env.NODE_ENV === 'production';

  if (isGAEnabled) {
    ReactGA.initialize('UA-148801394-1');
    ReactGA.pageview('/homepage');
  }
}
