import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import COLORS from 'constants/colors';

export const Heading = styled.div`
  background-color: ${({ theme: { theme } }) =>
    theme.background};
  color: ${({ theme: { theme } }) => theme.color};
  margin-top: -0.2rem;
  padding: 1rem 0;
  position: fixed;
  top: 5.2rem;
  width: 100%;
  z-index: 2;
`;

export const Description = styled.div`
  margin: 2rem 1rem;
`;

export const Example = styled.div`
  margin: 2rem 0;
`;

export const Examples = styled.div`
  border-left: 0.4rem solid #ffd000;
  padding-left: 2rem;
`;

export const Translation = styled.p`
  font-size: 1.2rem;
  color: ${({ theme: { theme } }) => theme.color};
`;

export const Wrapper = styled.div`
  margin: 4rem 0;
  padding: 0 2rem !important;
  width: 100% !important;
  min-height: 65rem;

  /* stylelint-disable */
  ${breakpoint('tablet')` 
    margin: 4rem 0 1rem 18.75%;
    width: 50% !important;
  `}
  /* stylelint-enable */  

  p,
  li,
  input {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .heading {
    background-color: ${COLORS.WHITE};
    padding: 1.4rem 2rem 1.6rem;
    width: 100%;
    z-index: 1;
  }

  i.checkmark.icon,
  i.remove.icon {
    margin-left: 1rem;
    position: absolute;
    right: -4.4rem;
  }
`;
