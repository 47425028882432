import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toPascalCase from 'to-pascal-case';
import styled from 'styled-components';
import Breadcrumb from 'components/menu/Breadcrumb';
import Task from 'components/task/Task';
import { requestCourse } from 'actions/course';
import { Wrapper } from 'styles/content';

const TaskWrapper = styled.div`
  display: inline-block;
  font-size: 1.4rem;
  margin: 2em 0;
  padding-bottom: 2em;
  text-align: center;
`;

type Props = {
  breadcrumbLinks: Array<Object>,
  courseName: string,
  courseType: string,
  requestCourse: Function,
  course: {
    course: Object,
    tasks: Array<Object>,
  },
};

class Tasks extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = { currentTask: {}, number: 0 };
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePreviousClick = this.handlePreviousClick.bind(
      this
    );
  }

  componentDidMount() {
    const {
      requestCourse,
      courseName,
      courseType,
    } = this.props;

    requestCourse(courseName, courseType);
  }

  handleNextClick(evt, task) {
    if (task) {
      this.setState({
        currentTask: task,
        number: this.state.number + 1,
      });
      this.renderTask(task);
    }
  }

  handlePreviousClick(evt, task) {
    if (task) {
      this.setState({
        currentTask: task,
        number: this.state.number - 1,
      });
      this.renderTask(task);
    }
  }

  renderTask(nextTask, previousTask, total) {
    const {
      id,
      question,
      answer,
      hint,
      isFormal,
      isPlural,
      tips,
    } = nextTask;
    const number = this.state.number;

    return (
      <Wrapper>
        <p>
          Question: {number + 1} / {total}
        </p>
        <Task
          id={id}
          key={id}
          question={question}
          answer={answer}
          isFirst={number === 0}
          isLast={number === total - 1}
          hint={hint}
          isFormal={isFormal}
          isPlural={isPlural}
          tips={tips}
          nextTask={nextTask}
          previousTask={previousTask}
          handleNextClick={this.handleNextClick}
          handlePreviousClick={this.handlePreviousClick}
        />
      </Wrapper>
    );
  }

  render() {
    const {
      course,
      courseName = '',
      breadcrumbLinks = [],
    } = this.props;
    const { tasks } = course.course;
    const nextTask = tasks && tasks[this.state.number];
    const previousTask =
      tasks && tasks[this.state.number - 1];
    const total = tasks.length;

    return (
      <TaskWrapper className="ui eight wide column">
        <div className="heading">
          <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
        </div>
        <Wrapper>
          <h2 className="ui header">
            {toPascalCase(courseName)}
          </h2>
          {nextTask &&
            this.renderTask(nextTask, previousTask, total)}
        </Wrapper>
      </TaskWrapper>
    );
  }
}

const mapStateToProps = state => ({
  requestCourse: state.requestCourse,
  course: state.course,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(requestCourse, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tasks);
