import React from 'react';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Present = () => (
  <Description>
    <h2 className="ui header">Präsens</h2>
    <p>
      The <b>Perfekt</b> is the most used verb tense for
      referring to past actions in German.
    </p>
    <p>
      It is constructed with the verb <b>sein</b> or{' '}
      <b>haben</b> and the past participle of the main verb.
    </p>
    <h2 className="ui header">Regular verbs</h2>
    <p>
      <u>Regular</u> verbs don&#8217;t change in the stem:
      just delete the infinitive ending
      <i>-en</i>, e.g in{' '}
      <i>
        leb<b>en</b>
      </i>{' '}
      (to live) and add different endings instead
    </p>
    <p>
      The singular endings of the regular verbs are{' '}
      <u>e, st, t</u>:
    </p>
    <Example>
      <Examples>
        <p>
          Ich leb<b>e</b> in Berlin
        </p>
        <Translation>I live in Berlin</Translation>
      </Examples>
      <Examples>
        <p>
          Du leb<b>st</b> in Stuttgart
        </p>
        <Translation>You live in Stuttgart</Translation>
        <p>
          Er leb<b>t</b> in München
        </p>
        <Translation>You live in Munich</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      The verb <i>wohnen</i>
    </h2>
    <p>
      The verb <i>wohnen</i> (to live, to reside) is also
      regular: It takes the same endings as <i>lebel</i>{' '}
      while the stem <i>wohn-</i> is unchanged
    </p>
    <Example>
      <Examples>
        <p>
          Ich wohn<u>e</u> hier
        </p>
        <Translation>I live here</Translation>
        <p>
          Wohn<u>st</u> du in diesem Haus?
        </p>
        <Translation>
          Do you live in this house?
        </Translation>
      </Examples>
      <Examples>
        <p>
          Sie wohn<u>en</u> in München und heißen Kraft
        </p>
        <Translation>
          They live in Munich and we are called Kraft
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">The imperative</h2>
    <p>
      The singular imperative is based on the <b>present</b>{' '}
      tense of the verb. The subject pronoun (du)
      disappears. Most verbs take the ending <b>e</b>
      in the 2nd person singular
    </p>
    <Example>
      <Examples>
        <p>
          Geh<u>e</u> zur Post!
        </p>
        <Translation>Go to the post office!</Translation>
        <p>
          Such<u>e</u> deine Schlüssel!
        </p>
        <Translation>Look for your keys!</Translation>
      </Examples>
    </Example>
    <p>
      However, in most case, the <b>-e</b> is not
      compulsary:
    </p>
    <Example>
      <Examples>
        <p>
          <u>Geh</u> zur Post!
        </p>
        <Translation>Go to the post office!</Translation>
        <p>
          <u>Such</u> deine Schlüssel!
        </p>
        <Translation>Look for your keys!</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">2nd person pulral(ihr)</h2>
    <p>
      All verbs take the ending <b>-t</b> in the 2nd person
      plural. The pronoun disappears here, too:
    </p>
    <Example>
      <Examples>
        <p>
          Guck<u>t</u> mal!
        </p>
        <Translation>Look!</Translation>
        <p>
          Komm<u>t</u> mit uns!
        </p>
        <Translation>Come with me!</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      1st person plural (wir) and the polite form (Sie)
    </h2>
    <p>
      Here, the subject pronouns are compulsary.{' '}
      <u>
        <b>wir</b> and <b>sie</b>
      </u>
      are necessary in order to know which person of the
      verb it is
    </p>
    <Example>
      <Examples>
        <p>
          <b>Machen wir</b> ein Picknick!
        </p>
        <Translation>
          Let&#8217;s have a picnic!
        </Translation>
        <p>
          <b>Fahren Sie</b> mit uns!
        </p>
        <Translation>Come with us!</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      <i>Wachsen</i> and <i>lassen</i> are two irregular
      verbs like <i>fahren</i>: Complete the sentences
    </h2>
    <p>
      <i>Fahren</i> is an irregular verb. In the 2nd and 3rd
      person singular forms, the <b>-a</b> in the root
      changes to an <b>-ä</b>.
    </p>
    <p>
      This vowel change is failry common. Other verbs which
      follow the same pattern are: <i>wachsen</i>(to grow)
      and <i>lassen</i> (to let/to leave).
    </p>
    <Example>
      <Examples>
        <p>
          Diese Blume <b>wächst</b> schnell
        </p>
        <Translation>These flowers grow fast</Translation>
        <p>
          Die Kinder <u>wachsen</u> noch schneller!
        </p>
        <Translation>
          The child is growing fast!
        </Translation>
        <p>
          Ich <u>lasse</u> dich meinen Koffer tragen
        </p>
        <Translation>
          I will let you carry my suitcase
        </Translation>
        <p>
          <u>Lässt</u> du bitte deine Schwester in Ruhe?
        </p>
        <Translation>Leave your sister alone</Translation>
        <p>
          Leider <u>wächst</u> die Armust ständig
        </p>
        <Translation>
          Unfortunately, the armust is constantly growing
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      The verb <i>werden</i>
    </h2>
    <p>
      <i>Werden</i> is a <u>very common</u> verb in German
      and means <i>become, to get</i> or <i>turn into</i>.
    </p>
    <Example>
      <Examples>
        <p>
          Der Himmel <b>wird</b> dunkel
        </p>
        <Translation>The sky is getting dark</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      What are <i>modal</i> verbs
    </h2>
    <p>
      A modal verb, in conjunction with another verb,
      descibes a likelihood, ability, permission or
      obligaion. There are six modal verbs:
    </p>
    <p>
      <i>müssen, können, dürfen, sollen, wollen</i> and{' '}
      <i>mögen/möchten</i>
    </p>
    <p>
      They are generally followed by another <u>verb</u>:
    </p>
    <Example>
      <Examples>
        <p>
          Ich <b>muss gehen</b>
        </p>
        <Translation>I must go</Translation>
      </Examples>
    </Example>
    <p>
      But they can alos be used as verbs in their own right:
    </p>
    <Example>
      <Examples>
        <p>
          Das Fahrrad <b>soll</b> in der Keller
        </p>
        <Translation>
          The bike should (go) into the cellar
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      How do you use <i>müssen</i>?
    </h2>
    <p>
      <i>Müssen</i> is used to express an obligation coming
      from someone else.
    </p>
    <Example>
      <Examples>
        <p>
          Ich <u>muss</u> jetzt ins Büro gehen
        </p>
        <Translation>I must go to the office</Translation>
      </Examples>
    </Example>
    <p>
      But it can also be used in order to express an
      obligation that comes from oneself
    </p>
    <Example>
      <Examples>
        <p>
          Wir <u>müssen</u> vor dem Rennen etwas essen
        </p>
        <Translation>
          We must eat before going for a run
        </Translation>
      </Examples>
    </Example>
    <p>
      Finallly <i>müssen</i> can be used to suppose or
      assume something
    </p>
    <Example>
      <Examples>
        <p>
          Tanja isst nur Salat, sie <u>muss</u> auf Diät
          sein
        </p>
        <Translation>
          Tanja only eats salad, she must be on (a) diet
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      When is <i>sollen</i> used?
    </h2>
    <p>
      <i>Sollen</i> is mostly used to express an obligation
      coming from someone else. Unlike <i>müssen</i>,{' '}
      <i>sollen</i> can&#8217;t express a self-obligation
    </p>
    <Example>
      <Examples>
        <p>
          Du <u>sollst</u> die Wohnung putzen
        </p>
        <Translation>
          You are supposed to clean the flat
        </Translation>
      </Examples>
    </Example>
    <p>But it is also used for polite requests</p>
    <Example>
      <Examples>
        <p>
          <u>Sollen</u> wir heute Abend essen gehen, Schatz?
        </p>
        <Translation>
          Shall we eat eat dessert now?
        </Translation>
      </Examples>
    </Example>
    <p>
      Lastly, <i>sollen</i> can also express a suposition or
      rumour
    </p>
    <Example>
      <Examples>
        <p>
          Hast du schon gehört? Maria <u>soll</u> schwanger
          sein...
        </p>
        <Translation>
          Have you heard? Apparently Maria&#8217;s
          pregnant...
        </Translation>
        <p>
          Die Schmidts <u>sollen</u> im Lotto gewonnen
          haben!
        </p>
        <Translation>
          The Schmidts have won the lottery
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      <i>wollen</i> vs. <i>möchten</i>
    </h2>
    <p>
      <i>Wollen</i> is stronger than <i>möchten</i> - it
      expresses a wish or offer more directly
    </p>
    <p>wollen:</p>
    <Example>
      <Examples>
        <p>
          Ich <u>will</u> Sängerin werden
        </p>
        <Translation>I want to be a singer</Translation>
        <p>
          Wir <u>wollen</u> in den Urlaub fahren
        </p>
        <Translation>We want to go on holidays</Translation>
      </Examples>
    </Example>
    <p>möchten:</p>
    <Example>
      <Examples>
        <p>
          Ich <u>möchte</u> diese Schuhe anprobieren
        </p>
        <Translation>
          I would like to try these shoes on
        </Translation>
        <p>
          <u>Möchtest</u> du noch ein Stück Kuchen?
        </p>
        <Translation>
          Would you like another slice of cake?
        </Translation>
      </Examples>
    </Example>
    <p>
      Möchten is in fact a conditional verb form (would
      like). The present tense form is <i>mögen</i> (like)
      However, as a modal verb, <i>möchten</i> is used much
      often: <i>Ich möchte Kirschen essen</i>
      (I would like to eat cherries).
    </p>
    <h2 className="ui header">Reflexive Pronouns</h2>
    <p>
      The reflexive pronoun is always placed directly{' '}
      <u>after</u> the conjugated verb:
    </p>
    <Example>
      <Examples>
        <p>
          Ich frage <b>mich</b>, wohin ich gehen muss
        </p>
        <Translation>
          I wonder where I have to go
        </Translation>
        <p>
          Ich habe <b>mich</b> gefragt, was er macht
        </p>
        <Translation>
          I wondered what he is doing
        </Translation>
        <p>
          Ich habe <b>mich</b> fragen, ob ich Recht habe
        </p>
        <Translation>
          I have to wonder if I am right
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Type in forms of the verb anrufen
    </h2>
    <p>
      Separable verbs are made of a verb and a prefix which
      is added to the verb and modifies its meaning: rufen
      (to shout) / <b>an</b>rufen (to call, to telephone)
    </p>
    <p>
      When the verb is in the infinitive, the prefix stays
      attached to the verb:
    </p>
    <Example>
      <Examples>
        <p>Ich muss sie anrufen</p>
        <Translation>I must call her</Translation>
      </Examples>
    </Example>
    <p>
      When the verb is conjugated, the prefix is seprated
      from it and moves to the end of the sentence:
    </p>
    <Example>
      <Examples>
        <p>Ich rufe an</p>
        <Translation>I will call</Translation>
        <p>Ich rufe meine Eltern an</p>
        <Translation>
          I&#8217;m calling my parents
        </Translation>
      </Examples>
    </Example>
  </Description>
);

export default Present;
