export const simplify = (sentence: string = '') => {
  const content = sentence.value
    ? sentence.value
    : sentence;

  return content
    .replace('!', '')
    .replace('?', '')
    .replace('ä', 'ae')
    .replace('ö', 'oe')
    .replace('ü', 'ue')
    .replace('ß', 'ss')
    .replace(',', '')
    .replace('.', '')
    .replace(/\s+/g, ' ')
    .trim()
    .toUpperCase();
};

export const properCase = (str: string) => {
  str = str.toLowerCase();
  str = str.split(' ');

  for (let i = 0; i < str.length; i++) {
    str[i] =
      str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }

  return str.join(' ');
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const insertText = (
  text: string = '',
  input: string
) => {
  const [FIREFOX, IE] = ['FireFox', 'IE'];
  const cursor = { pos: 0 };

  if (input === undefined) {
    return;
  }

  const scrollPos = input.scrollTop;
  const selection = document.selection ? IE : false;
  const browser =
    input.selectionStart || input.selectionStart === '0'
      ? FIREFOX
      : selection;

  if (browser === IE) {
    input.focus();
    const range = document.selection.createRange();
    range.moveStart('character', -input.value.length);
    cursor.pos = range.text.length;
  } else if (browser === FIREFOX) {
    cursor.pos = input.selectionStart;
  }

  const front = input.value.substring(0, cursor.pos);
  const back = input.value.substring(
    cursor.pos,
    input.value.length
  );

  input.value = front + text + back;
  cursor.pos += text.length;

  if (browser === IE) {
    input.focus();
    const range = document.selection.createRange();
    range.moveStart('character', -input.value.length);
    range.moveStart('character', cursor.pos);
    range.moveEnd('character', 0);
    range.select();
  } else if (browser === FIREFOX) {
    input.selectionStart = cursor.pos;
    input.selectionEnd = cursor.pos;
    input.focus();
  }

  input.scrollTop = scrollPos;
};
