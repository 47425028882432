import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  margin: 0 2rem;
`;

/* eslint-disable max-len */
const Logo = () => (
  <Svg
    viewBox="0 0 1000 1000"
    width="2rem">
    <g>
      <g>
        <g>
          <path fill="#ffd000" d="M692.5,473.3l-36-13.1l34.7-16.3c85-39.8,131.8-108.3,131.8-193c0-79.9-41.6-147.8-114.1-186.4C641.5,24.7,564.4,10,423.7,10C318.1,10,204,18,143.9,29.5v946.7c37.8,5.4,115.9,13.9,233.7,13.9c181.6,0,305.6-28.5,379.3-87c64.9-52.5,99.2-122.7,99.2-202.8C856.1,593.9,796.4,511.3,692.5,473.3z M353.5,168.1l13.9-1.8c22.9-2.9,50.3-4.3,83.6-4.3c102.8,0,159.4,41.3,159.4,116.2c0,77.3-67.7,123.5-181,123.5h-75.9L353.5,168.1L353.5,168.1z M442.4,835.1c-30.5,0-54.6,0-74.3-1.7l-14.6-1.3V550.8h78.8c127.9,0,201.2,51.3,201.2,140.7C633.5,782.8,563.9,835.1,442.4,835.1z" />
        </g>
      </g>
    </g>
  </Svg>
);

export default Logo;
