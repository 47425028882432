// @flow
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import cx from 'classnames';
import COLORS from 'constants/colors';

const Wrapper = styled.div`
  border-right: 0 !important;
  margin-left: 1rem !important;
  margin-top: 1rem !important;

  .active {
    .item {
      border: 0 !important;
      color: ${COLORS.DARK_JUNGLE_GREEN} !important;
    }
  }

  .item {
    color: ${COLORS.DARK_JUNGLE_GREEN} !important;
    font-size: 1.6rem;
  }
`;

type Props = {
  history: Array<string>,
  menuItems: Array<Object>,
};

class SideMenu extends React.PureComponent<Props> {
  handleClick = link => {
    this.props.history.push(link);
  };

  render() {
    const { menuItems } = this.props;
    const items = menuItems.map(({ name, link }, index) => (
      <Link
        to={link}
        key={index}
        className={cx({
          item: true,
          active: link === window.location.pathname,
        })}
        onClick={this.handleClick.bind(this, link)}>
        {name}
      </Link>
    ));

    return (
      <Wrapper className="ui vertical fluid tabular menu">
        {items}
      </Wrapper>
    );
  }
}

export default withRouter(SideMenu);
