import React from 'react';

const WeakVerbs = () => (
  <table className="ui celled table">
    <thead>
      <tr>
        <th>Infinitiv</th>
        <th>Präteritum</th>
        <th>ich</th>
        <th>du</th>
        <th>er / sie / es</th>
        <th>wir</th>
        <th>ihr</th>
        <th>sie</th>
        <th>Englisch</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>fühlen</td>
        <td>fühlte</td>
        <td>fühlte</td>
        <td>fühltest</td>
        <td>fühlte</td>
        <td>fühlten</td>
        <td>fühltet</td>
        <td>fühlten</td>
        <td>felt</td>
      </tr>
      <tr>
        <td>hören</td>
        <td>hörte</td>
        <td>hörte</td>
        <td>hörtest</td>
        <td>hörte</td>
        <td>hörten</td>
        <td>hörtet</td>
        <td>hörten</td>
        <td>heard</td>
      </tr>
      <tr>
        <td>lächeln</td>
        <td>lächelte</td>
        <td>lächelte</td>
        <td>lächeltest</td>
        <td>lächelte</td>
        <td>lächelten</td>
        <td>lächeltet</td>
        <td>lächelten</td>
        <td>smiled</td>
      </tr>
      <tr>
        <td>lernen</td>
        <td>lernte</td>
        <td>lernte</td>
        <td>lerntest</td>
        <td>lernte</td>
        <td>lernten</td>
        <td>lerntet</td>
        <td>lernten</td>
        <td>learned</td>
      </tr>
      <tr>
        <td>machen</td>
        <td>machte</td>
        <td>machte</td>
        <td>machtest</td>
        <td>machte</td>
        <td>machten</td>
        <td>machtet</td>
        <td>machten</td>
        <td>made</td>
      </tr>
      <tr>
        <td>sagen</td>
        <td>sagte</td>
        <td>sagte</td>
        <td>sagtest</td>
        <td>sagte</td>
        <td>sagten</td>
        <td>sagtet</td>
        <td>sagten</td>
        <td>said</td>
      </tr>
      <tr>
        <td>sammeln</td>
        <td>sammelte</td>
        <td>sammelte</td>
        <td>sammeltest</td>
        <td>sammelte</td>
        <td>sammelten</td>
        <td>sammeltet</td>
        <td>sammelten</td>
        <td>collected</td>
      </tr>
      <tr>
        <td>schmecke</td>
        <td>schmeckte</td>
        <td>schmeckte</td>
        <td>schmecktest</td>
        <td>schmeckte</td>
        <td>schmeckten</td>
        <td>schmecktet</td>
        <td>schmeckten</td>
        <td>tasted</td>
      </tr>
      <tr>
        <td>vermissen</td>
        <td>vermisste</td>
        <td>vermisste</td>
        <td>vermisstest</td>
        <td>vermisste</td>
        <td>vermissen</td>
        <td>vermisstet</td>
        <td>vermissten</td>
        <td>missed</td>
      </tr>
      <tr>
        <td>versuchen</td>
        <td>versuchte</td>
        <td>versuchte</td>
        <td>versuchtest</td>
        <td>versuchte</td>
        <td>versuchten</td>
        <td>versuchtet</td>
        <td>versuchten</td>
        <td>tried</td>
      </tr>
    </tbody>
  </table>
);

export default WeakVerbs;
