import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
// import Background from 'components/shared/Background';
import * as actions from 'actions/course';
import Img01 from 'images/homepage-01.png';
import Img02 from 'images/homepage-02.png';
import Img03 from 'images/homepage-03.png';

const Wrapper = styled.div`
  height: 100%;
  min-height: 50vh;
  padding: 2rem 2rem;
  text-align: left;

  /* stylelint-disable */
  ${breakpoint('tablet')`
    text-align: center;
    padding: 5rem 0;
  `} /* stylelint-enable */
`;

const H2 = styled.h1`
  font-size: 2.5rem;

  /* stylelint-disable */
  ${breakpoint('tablet')`
    font-size: 4rem;
  `} /* stylelint-enable */
`;

const H3 = styled.h1`
  font-size: 2rem;

  /* stylelint-disable */
  ${breakpoint('tablet')`
    font-size: 3rem;
  `} /* stylelint-enable */
`;

const P = styled.h1`
  font-size: 1.8rem;
  font-weight: normal;

  /* stylelint-disable */
  ${breakpoint('tablet')`
    font-size: 2rem;
  `} /* stylelint-enable */
`;

const Image = styled.img`
  width: 100%;
  max-width: 51.6rem;
`;

const HomePage = () => (
  <Wrapper>
    <H2>What is Blebber?</H2>
    <P>
      Blebber is a free education platform for beginners to
      advance language learners.
    </P>
    <Image src={Img01} />
    <H2>How does Blebber work?</H2>
    <H3>Learn smart</H3>
    <P>
      Learn German grammar with over 12 sections of
      introductions and practices.
    </P>
    <Image src={Img02} />
    <P>
      Each section is filled with practical examples and
      tips you can apply <br />
      in your day today conversations.
    </P>
    <H2>References at your finger tips</H2>
    <P>
      Benefit from the reference tables for your practice
      exercises.
    </P>
    <Image src={Img03} maxWidth="700" />
    <P>
      Quick access to reference tables means, your practice
      exercises are much easier to complete.
    </P>
  </Wrapper>
);

export default connect(
  state => state,
  dispatch => bindActionCreators({ actions }, dispatch)
)(HomePage);
