import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  getCourseName,
  getCourseType,
} from 'reducers/course';
import getCourse from 'api/course';
import * as actions from 'actions/course';

export function* fetchCourse() {
  try {
    const courseName = yield select(getCourseName);
    const courseType = yield select(getCourseType);
    const course = yield call(
      getCourse,
      courseType,
      courseName
    );

    yield put(actions.courseReceived(course[0].course));
  } catch (error) {
    yield put(actions.requestCourseFailed(error));
  }
}

export function* runFetchCourse() {
  yield takeLatest(
    'REQUEST_COURSE',
    fetchCourse,
    getCourseName,
    getCourseType
  );
}
