import React from 'react';
import styled from 'styled-components';
import { Popup as PopUp } from 'semantic-ui-react';

const P = styled.p`
  font-size: 1.4rem;
`;

const Popup = ({ content }: { content: string }) => (
  <PopUp
    trigger={<i className="info circle icon" />}
    flowing
    hoverable>
    {
      <div>
        {/* eslint-disable */}
        <P dangerouslySetInnerHTML={{ __html: content }} />
        {/* eslint-enable */}
      </div>
    }
  </PopUp>
);

export default Popup;
