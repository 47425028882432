// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grammar from 'components/links/Grammar';
// import Conversation from 'components/links/Conversation';
import Reference from 'components/links/Reference';
import Logo from 'components/shared/Logo';
import ThemeSelector from 'components/shared/ThemeSelector';

const DropDownItem = styled.div.attrs({
  className: 'ui simple dropdown item',
})``;

const Link = styled.a`
  font-size: 1.6rem;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .item.home {
    display: inline-block;
    border: 0.1rem solid red;
  }

  ${breakpoint('tablet')`
    padding-left: 2rem !important;
    padding-right: 2rem !important;

    .item.home {
      display: hidden;
    }
  `}
`;

const HomeLink = styled.a`
  display: none !important;

  ${breakpoint('tablet')`
    display: flex !important;
  `}
`;

function Header({
  handleThemeChange,
}: {
  handleThemeChange: () => {},
}) {
  const [showMenu, setShowMenu] = useState(false);
  const handleMenuClick = evt => {
    evt.preventDefault();
    setShowMenu(!showMenu);
  };

  return (
    <div className="ui page grid">
      <div className="computer tablet only row">
        <div className="ui fixed inverted menu">
          <div className="ui container">
            <Link href="/" className="item">
              <Logo />
            </Link>
            <HomeLink href="/" className="item home">
              Home
            </HomeLink>
            <DropDownItem>
              Grammar <i className="dropdown icon" />
              <Grammar className="menu" />
            </DropDownItem>
            {/* <DropDownItem>
              Conversation <i className="dropdown icon" />
              <Conversation className="menu" />
            </div> */}
            <DropDownItem>
              Reference <i className="dropdown icon" />
              <Reference className="menu" />
            </DropDownItem>
            <Link
              href="https://blog.blebber.com"
              className="item">
              Blog
            </Link>
            <DropDownItem>
              <ThemeSelector
                handleThemeChange={handleThemeChange}
              />
            </DropDownItem>
          </div>
        </div>
      </div>
      <div className="mobile only narrow row">
        <div className="mobile only narrow row">
          <div className="ui inverted navbar menu">
            <Link href="/" className="item">
              <Logo />
            </Link>
            <div className="right menu open">
              <a
                href
                className="menu item"
                onClick={handleMenuClick}>
                <i className="bars icon"></i>
              </a>
            </div>
          </div>
          {showMenu && (
            <div className="ui vertical navbar menu">
              <HomeLink href="/" className="item home">
                Home
              </HomeLink>
              <DropDownItem>
                Grammar <i className="dropdown icon" />
                <Grammar className="menu" />
              </DropDownItem>
              {/* <DropDownItem>
                Conversation <i className="dropdown icon" />
                <Conversation className="menu" />
              </DropDownItem> */}
              <DropDownItem>
                Reference <i className="dropdown icon" />
                <Reference className="menu" />
              </DropDownItem>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
