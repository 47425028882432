import React from 'react';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Pronoun = () => (
  <Description>
    <h2 className="ui header">
      Possesive pronouns in the accusative
    </h2>
    <p>
      Some verbs take the accusative case, and masculaine
      articles following These verbs change from{' '}
      <u>der/ein</u> to{' '}
      <u>
        de<b>n</b>/ein<b>en</b>
      </u>
    </p>
    <p>
      Some prepositions, like <b>ohne</b> and <b>für</b>{' '}
      also need the accusative. Just add <b>-en</b> to the
      masculine possesive pronoun
    </p>
    <Example>
      <Examples>
        <p>
          Ich bin ohne <u>meinen</u> Freund und ohne meine
          Schwester hier
        </p>
        <Translation>
          I am here without my boyfriend and my sister
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Reflexive pronouns in the accusative
    </h2>
    <p>
      Reflexive pronouns go along with reflexive{' '}
      <u>verbs</u>. They always refer to the subject of the
      sentence
    </p>
    <Example>
      <Examples>
        <p>
          Ich entschuldige <b>mich</b> bei ihr
        </p>
        <Translation>I am apologising to her</Translation>
      </Examples>
    </Example>
    <p>
      The reflexive pronouns for the first and the second
      person singular and plural are the same as the
      personal pronouns in the accusative. Only the third
      person singular and the plural forms are different:
      here for both you use the reflexive pronoun{' '}
      <b>sich</b>
    </p>
    <Example>
      <Examples>
        <p>
          <b>Er</b> bedankt <b>sich</b> für das Geschenk
        </p>
        <Translation>
          He is giving his thanks for the present
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Reflexive pronouns in the dative
    </h2>
    <p>
      Depending on the <i>verb</i>, reflexive pronouns may
      also be in the dative form.
    </p>
    <Example>
      <Examples>
        <p>
          Er merkt <b>sich</b> den Weg
        </p>
        <Translation>He is memorising the way</Translation>
      </Examples>
    </Example>
    <p>
      The reflexive pronouns in the dative are the same as
      in the accusative - only the{' '}
      <u>first and the second</u> person singular are
      different
    </p>
    <Example>
      <Examples>
        <p>
          Ich mache <b>mir</b> Sorgen
        </p>
        <Translation>I am worried</Translation>
      </Examples>
    </Example>
  </Description>
);

export default Pronoun;
