import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Routes from 'components/page/Routes';

const Wrapper = styled.div`
  width: 100% !important;
  margin-top: 6rem;

  html {
    font-size: 62.5%; /* 10px browser default */
  }

  h2 {
    &.header {
      font-size: 2.4rem !important;
      margin-top: 1rem !important;

      ${breakpoint('tablet')`
        display: inline-block !important;
      `}
    }
  }

  h3 {
    &.header {
      font-size: 2rem !important;
      margin-top: 3rem !important;
    }
  }

  .ui {
    .button {
      font-size: 1.2rem !important;

      ${breakpoint('tablet')`
        font-size: 1.4rem !important;
      `}
    }

    .breadcrumb {
      font-size: 1.4rem;
    }

    .icon {
      font-size: 2.2rem;

      &.message > .icon:not(.close) {
        font-size: 3.2rem;
        padding: 1rem;
      }
    }

    .label {
      font-size: 1.4rem;
      margin-left: 1rem;
    }

    .left,
    .right {
      font-size: 1.4rem !important;
    }

    .inverted {
      .menu {
        font-size: 1.6rem !important;
      }
    }

    &.input {
      & input:focus {
        border: 0.2rem solid $input-border-active-color;
      }
    }

    &.message {
      padding: 1rem 2rem;

      .content {
        margin: 0;
      }
    }

    .blue,
    .green,
    .yellow {
      &.tag {
        color: $label-font-color !important;
        font-size: 1.2rem;
        margin: 0 2rem;
      }
    }
  }
`;

const Main = () => (
  <Wrapper>
    <Routes />
  </Wrapper>
);

export default Main;
