import Articles from '../components/table/Articles';
import AdjectiveEndings from '../components/table/AdjectiveEndings';
import Prepositions from '../components/table/Prepositions';
import Interrogatives from '../components/table/Interrogatives';
import CoordinatingConjunctions from '../components/table/CoordinatingConjunctions';
import ModalVerbs from '../components/table/ModalVerbs';
import SubordinatingConjunctions from '../components/table/SubordinatingConjunctions';
import WeakVerbs from '../components/table/WeakVerbs';

export const conversationMenuItems = [
  { name: 'People', link: '/conversation/people' },
  { name: 'Appearance', link: '/conversation/appearance' },
];

export const grammarMenuItems = [
  { name: 'Adjective', link: '/grammar/adjective' },
  { name: 'Dative', link: '/grammar/dative' },
  { name: 'Future', link: '/grammar/future' },
  { name: 'Genitive', link: '/grammar/genitive' },
  { name: 'Conjugation', link: '/grammar/conjugation' },
  { name: 'Passive', link: '/grammar/passive' },
  { name: 'Plural', link: '/grammar/plural' },
  { name: 'Perfect', link: '/grammar/perfect' },
  { name: 'Present', link: '/grammar/present' },
  { name: 'Preposition', link: '/grammar/preposition' },
  { name: 'Preterite', link: '/grammar/preterite' },
  { name: 'Pronouon', link: '/grammar/pronoun' },
];

export const referenceMenuItems = [
  { name: 'Articles', link: '/reference/articles' },
  {
    name: 'Adjective Endings',
    link: '/reference/adjective-endings',
  },
  {
    name: 'Interrogatives',
    link: '/reference/interrogatives',
  },
  {
    name: 'Prepositions',
    link: '/reference/prepositions',
  },
  {
    name: 'Coordinating Conjunctions',
    link: '/reference/coordinating-conjunctions',
  },
  {
    name: 'Subordinating Conjunctions',
    link: '/reference/subordinating-conjunctions',
  },
  {
    name: 'Modal Verbs',
    link: '/reference/modal-verbs',
  },
  {
    name: 'Weak Verbs',
    link: '/reference/weak-verbs',
  },
];

export const panels = [
  { title: 'Articles', content: Articles() },
  {
    title: 'Adjective Endings',
    content: AdjectiveEndings(),
  },
  {
    title: 'Coordinating Conjunctions',
    content: CoordinatingConjunctions(),
  },
  { title: 'Interrogatives', content: Interrogatives() },
  { title: 'Modal Verbs', content: ModalVerbs() },
  { title: 'Prepositions', content: Prepositions() },
  {
    title: 'Subordinating Conjunctions',
    content: SubordinatingConjunctions(),
  },
  { title: 'Weak Verbs', content: WeakVerbs() },
];
