import React from 'react';

const Grammar = ({
  className = '',
}: {
  className: string,
}) => (
  <div className={className}>
    <a className="item" href="/grammar/adjective">
      Adjective
    </a>
    <a className="item" href="/grammar/conjugation">
      Conjugation
    </a>
    <a className="item" href="/grammar/dative">
      Dative
    </a>
    <a className="item" href="/grammar/genitive">
      Genitive
    </a>
    <a className="item" href="/grammar/future">
      Future
    </a>
    <a className="item" href="/grammar/passive">
      Passive
    </a>
    <a className="item" href="/grammar/plural">
      Plural
    </a>
    <a className="item" href="/grammar/perfect">
      Perfect
    </a>
    <a className="item" href="/grammar/present">
      Present
    </a>
    <a className="item" href="/grammar/preterite">
      Preterite
    </a>
    <a className="item" href="/grammar/preposition">
      Preposition
    </a>
    <a className="item" href="/grammar/pronoun">
      Pronoun
    </a>
  </div>
);

export default Grammar;
