import { useState, useEffect } from 'react';

export const useLocalStorage = (key, defaultValue) => {
  const getValue = () =>
    JSON.parse(
      window.localStorage.getItem(key) || JSON.stringify(defaultValue)
    );

  const [storage, setStorage] = useState(getValue);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storage));
  }, [key, storage]); // Only re-run the effect if *key* or *storage* changes

  return [storage, setStorage];
};
