import React from 'react';

const CoordinatingConjunctions = () => (
  <table className="ui celled table">
    <thead>
      <tr>
        <th>Preposition</th>
        <th>Meaning</th>
        <th>Preposition</th>
        <th>Meaning</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>als</b></td>
        <td>as, than, when</td>
        <td><b>als ob, als wenn</b></td>
        <td>as if</td>
      </tr>
      <tr>
        <td><b>bevor</b></td>
        <td>before</td>
        <td><b>bis</b></td>
        <td>until</td>
      </tr>
      <tr>
        <td><b>da</b></td>
        <td>because, since, as</td>
        <td><b>damit</b></td>
        <td>so that</td>
      </tr>
      <tr>
        <td><b>dass</b></td>
        <td>that</td>
        <td><b>ehe</b>*</td>
        <td>before</td>
      </tr>
      <tr>
        <td><b>falls</b></td>
        <td>in case, if</td>
        <td><b>indem</b></td>
        <td>by ...-ing</td>
      </tr>
      <tr>
        <td><b>nachdem</b></td>
        <td>after</td>
        <td><b>ob</b></td>
        <td>whether, if</td>
      </tr>
      <tr>
        <td><b>obwohl, obschon, obgliech</b></td>
        <td>although</td>
        <td><b>seit, seitdem</b></td>
        <td>since</td>
      </tr>
      <tr>
        <td><b>sobald</b></td>
        <td>as soon as</td>
        <td><b>so dass, sodass</b></td>
        <td>so that</td>
      </tr>
      <tr>
        <td><b>sooft</b></td>
        <td>as often as</td>
        <td><b>solang, solange</b></td>
        <td>as long as</td>
      </tr>
      <tr>
        <td><b>soviel</b></td>
        <td>as much as</td>
        <td><b>soweit</b></td>
        <td>as far as</td>
      </tr>
      <tr>
        <td><b>trotzdem</b></td>
        <td>even though</td>
        <td><b>während, währenddem</b></td>
        <td>while. whereas</td>
      </tr>
      <tr>
        <td><b>weil</b></td>
        <td>because</td>
        <td><b>wenn</b></td>
        <td>when, whenever, if</td>
      </tr>
      <tr>
        <td><b>weshalb</b></td>
        <td>why, for what reason</td>
        <td><b>&nbsp;</b></td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
);

export default CoordinatingConjunctions;
