import React from 'react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 1.4rem;
`;

/* eslint-disable import/prefer-default-export */
export const renderReferences = (panels = []) => {
  return panels.map((panel, index) => (
    <Wrapper key={index}>
      <Popup
        trigger={
          <button className="ui button" icon="flask">
            {panel.title}
          </button>
        }
        flowing
        on="click">
        {panel.content}
      </Popup>
    </Wrapper>
  ));
};
