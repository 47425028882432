import React from 'react';

const CharButton = ({
  handleAddChar,
  char,
}: {
  handleAddChar: SyntheticEvent<HTMLInputElement>,
  char: string,
}) => (
  <button
    className="ui button"
    onClick={() => handleAddChar(char)}>
    {char}
  </button>
);

export default CharButton;
