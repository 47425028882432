// @flow
import React from 'react';

const Conversation = ({
  className = '',
}: {
  className: string,
}) => (
  <div className={className}>
    <h4 className="ui inverted header">Conversation</h4>
    <a className="item" href="/conversation/people">
      People
    </a>
  </div>
);

export default Conversation;
