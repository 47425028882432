export const ALUMINIUM = '#979797';
export const BLACK = '#000';
export const DARK_JUNGLE_GREEN = '#1b1c1d';
export const HARLEQUIN = '#39ff14';
export const PERSIAN_ROSE = '#ff1d8e';
export const TANGERINE = '#fed001';
export const WHITE = '#fff';
export const ZIRCON = '#e0e1e2';

export default {
  ALUMINIUM,
  BLACK,
  DARK_JUNGLE_GREEN,
  HARLEQUIN,
  PERSIAN_ROSE,
  TANGERINE,
  ZIRCON,
  WHITE,
};
