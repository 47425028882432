// @flow
import * as React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import COLORS from 'constants/colors';

const Wrapper = styled.aside`
  background-color: ${({ theme: { theme } }) =>
    theme.background};
  display: none !important;
  min-height: 100vh;
  overflow: hidden;
  position: fixed !important;

  ${breakpoint('tablet')`
    display: inline-block !important;
  `}

  &.left {
    background-color: ${COLORS.TANGERINE};
  }

  &.right {
    background-color: ${({ theme: { theme } }) =>
      theme.background};
    margin-top: 4rem;
    right: 1rem;
  }

  .item {
    color: ${COLORS.WHITE};
    font-size: 1.6rem;

    &.active {
      color: ${COLORS.DARK_JUNGLE_GREEN};
    }
  }
`;

type Props = {
  children: React.Node,
  side: string,
  size: string,
};

const Aside = ({
  children,
  side = 'left',
  size = 'four',
}: Props) => (
  <Wrapper className={`${side} ui ${size} wide column`}>
    {children}
  </Wrapper>
);

export default Aside;
