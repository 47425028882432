import * as actions from 'constants/actions';
import type { Action } from 'types/Action';

const initialState = {
  course: {
    name: '',
    tasks: [],
  },
};

const course = (
  state: Object = initialState,
  action: Action
) => {
  switch (action.type) {
    case actions.REQUEST_COURSE:
      return {
        ...state,
        courseName: action.courseName,
        courseType: action.courseType,
      };
    case actions.REQUEST_COURSE_SUCCESS:
      return {
        ...state,
        course: action.course,
      };
    case actions.REQUEST_COURSE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getCourseName = state =>
  state.course.courseName;

export const getCourseType = state =>
  state.course.courseType;

export default course;
