// @flow
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import isEmpty from 'lodash/isEmpty';
import Aside from 'components/page/Aside';
import SideMenu from 'components/menu/SideMenu';
import Breadcrumb from 'components/menu/Breadcrumb';
import { referenceMenuItems } from 'constants/page';
import { Heading, Wrapper } from 'styles/content';

const ContentWrapper = styled(Wrapper)`
  /* stylelint-disable */
  ${breakpoint('tablet')` 
    margin-bottom: 4rem;
  `} /* stylelint-enable */
`;

const Link = styled.a`
  display: block;
  font-size: 1.6rem;
  margin: 1rem 0;
`;

const ReferencePage = ({
  title = '',
  description = '',
  content,
  link,
}: {
  title?: string,
  description?: string,
  content: string,
  link: string,
}) => {
  const breadcrumbLinks = [
    { name: 'Home', link: '/' },
    { name: 'Reference', link: '/reference' },
    {
      name: `${title}`,
      link: `/reference/${link}`,
      isActive: true,
    },
  ];

  return (
    <div className="ui grid">
      <Aside size="three">
        <SideMenu menuItems={referenceMenuItems} />
      </Aside>
      <ContentWrapper className="ui twelve wide column">
        <Heading>
          <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
        </Heading>
        {!isEmpty(content) ? (
          <>
            <h2 className="ui header">{title}</h2>
            <p>{description}</p>
            {content}
          </>
        ) : (
          <ContentWrapper>
            <h2 className="ui header">References</h2>
            <p>
              Here are some common German grammar reference
              tables:
            </p>
            <Link href="/reference/articles">Artcles</Link>
            <Link href="/reference/adjective-endings">
              Adjective Endings
            </Link>
            <Link href="/reference/coordinating-conjunctions">
              Coordinating Conjunctions
            </Link>
            <Link href="/reference/interrogatives">
              Interrogatives
            </Link>
            <Link href="/reference/modal-verbs">
              Modal Verbs
            </Link>
            <Link href="/reference/prepositions">
              Prepositions
            </Link>
            <Link href="/reference/subordinating-conjunctions">
              Subordinating Conjunctions
            </Link>
            <Link href="/reference/weak-verbs">
              Weak Verbs
            </Link>
          </ContentWrapper>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ReferencePage;
