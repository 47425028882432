import React from 'react';
import { Popup } from 'semantic-ui-react';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Dative = () => (
  <Description>
    <h2 className="ui header">Dative</h2>
    <p>
      Dative is another case in German. It is used for
      indirect objects
    </p>
    <p>
      Like the nominative and accusative cases, the dative
      <u>causes the declension of</u>
      articles and adjectives
    </p>
    <Example>
      <Examples>
        <p>
          Ich gebe <b>meiner Mutter</b> ein Geschenk
        </p>
        <Translation>
          I give my mother a present
        </Translation>
      </Examples>
    </Example>
    <p>
      The objects must come in the following order:
      <u>dative than accusative</u>
      <Popup
        trigger={<i className="info circle icon" />}
        flowing
        hoverable>
        {
          <div>
            <i>Meine Mutter</i>(dative) comes before{' '}
            <i>ein Geschenk</i>(accusative)
          </div>
        }
      </Popup>
    </p>
    <p>
      Unlike in the accusative, all the articles in the
      dative change: The masculine and neuter definite
      article is <u>dem</u> in the dative
    </p>
    <Example>
      <Examples>
        <p>
          Ich gebe <b>dem</b> Mann ein Geschenk
        </p>
        <Translation>I gave a gift to the man</Translation>
        <p>
          Ich gebe <b>dem</b> Kind ein Geschenk
        </p>
        <Translation>
          I gave a gift to the child
        </Translation>
      </Examples>
    </Example>
    <p>
      In the feminine, the definite dative article is{' '}
      <u>der</u>
    </p>
    <Example>
      <Examples>
        <p>
          Ich gebe <b>der</b> Frau ein Geschenk
        </p>
        <Translation>
          I gave a gift to the woman
        </Translation>
      </Examples>
    </Example>
    <p>
      Some verbs such as <i>hilfen</i> (to help),
      gratulieren (to congratulate),
      <i>danken</i> (to thank) and <i>widersprechen</i> (to
      contradict) are always folllowed by the dative in
      German, where they would be followed by direct objects
      in English
    </p>
    <Example>
      <Examples>
        <p>
          Kannst du dein<b>er</b> Schwester helfen?
        </p>
        <Translation>Can you help your sister?</Translation>
        <p>
          Ich danke mein<b>em</b> Vater
        </p>
        <Translation>I thank my father</Translation>
      </Examples>
    </Example>
  </Description>
);

export default Dative;
