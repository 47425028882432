import React from 'react';
import Popup from 'components/shared/Popup';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Genitive = () => (
  <Description>
    <h2 className="ui header">
      The genitive in the feminine and the plural
    </h2>
    <p>
      With feminine nouns in the genitive the article is{' '}
      <b>der</b> but there is <u>no</u>
      change to the ending of the noun:
    </p>
    <Example>
      <Examples>
        <p>
          Die Bluse <b>der</b> Frau
        </p>
        <Translation>The woman&#8217;s blouse</Translation>
      </Examples>
    </Example>
    <p>
      In the plural you use <b>der</b> along with{' '}
      <u>plural</u> form of the noun
    </p>
    <Example>
      <Examples>
        <p>
          Die Adressen <b>der</b> Freunde und <b>der</b>{' '}
          Freundinnen
        </p>
        <Translation>
          The freinds&#8217;s addresses
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Prepositions that take the genitive
    </h2>
    <p>
      With the prepositions <i>während</i> (while, during)
      you always use the <u>geinitve</u> case
    </p>
    <Example>
      <Examples>
        <p>
          <b>Während des Kochens</b> telefonierte David
          ständig
        </p>
        <Translation>
          David always phoned while cooking
        </Translation>
      </Examples>
    </Example>
    <p>
      <i>Wegen</i> means <i>because of</i> or <u>due to</u>
    </p>
    <Example>
      <Examples>
        <p>
          <b>Wegen des schlechsten Wetters</b> sind wir zu
          Hause geblieben
        </p>
        <Translation>
          Because of the bad weather we stayed home
        </Translation>
        <Popup content="Spoken, <i>wegen</i> is often used with the dative: <i>Wegen dem schlechten Wetter...</i>" />
      </Examples>
    </Example>
    <h2 className="ui header">
      <i>von</i> + dative
    </h2>
    <p>
      The genitive is usually used in writing, but spoken
      you&#8217;ll more often hear <b>von + dative</b>
    </p>
    <Example>
      <Examples>
        <p>
          Der Sohn von mein<b>em</b> Nachbarn studiert an
          der Universität
        </p>
        <Translation>
          My neighbour&#8217;s son studies at the university
        </Translation>
        <p>
          Die Bücher von der Frau sind klassisiche Romane
        </p>
        <Translation>
          The woman&#8217;s books are classic novels
        </Translation>
      </Examples>
    </Example>
  </Description>
);

export default Genitive;
