import React from 'react';
import Popup from 'components/shared/Popup';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Conjugation = () => (
  <Description>
    <h2 className="ui header">Conjugation</h2>
    <p>
      A conjunction is a <b>little word</b> that connects
      two clauses:
    </p>
    <p>
      <i>You're so fine, and you're mine</i>
    </p>
    <p>
      <i>
        Long stemmed roses are the way to your heart, but he
        needs to start with your head.
      </i>
    </p>
    <p>
      In German, a conjunction either <b>coordinates</b> two{' '}
      <b>equally important</b> clauses, or it{' '}
      <b>subordinates</b> one clause to the other.
    </p>
    <p>
      Subordinating conjunctions make the verb go to the end
      in the clause they introduce, while coordinating
      conjunctions leave the verb position unchanged (the
      verb will usually, but not always, be in position 2
      after a coordinating conjunction).
    </p>
    <p>
      It is constructed with the verb <b>sein</b> or{' '}
      <b>haben</b> and the past participle of the main verb.
    </p>
    <h2 className="ui header">
      The conjugations <i>aber</i> and <i>oder</i>
    </h2>
    <p>
      <i>Aber</i> unites contrasting ideas and means{' '}
      <u>but</u>
    </p>
    <Example>
      <Examples>
        <p>
          Er ist hübsch, <b>aber</b> er gefällt mir nicht
        </p>
        <Translation>
          He is handsome, but I do not like him
        </Translation>
      </Examples>
    </Example>
    <p>
      The conjugation <i>oder</i> means <u>or</u> and is
      plcaed between two distinct options
    </p>
    <Example>
      <Examples>
        <p>
          Was magst du lieber, Strand <b>oder</b> Berge?
        </p>
        <Translation>
          What do you prefer, beach or mountains?
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Prepositions that take the genitive
    </h2>
    <p>
      With the prepositions <i>während</i> (while, during)
      you always use the <u>geinitve</u> case
    </p>
    <Example>
      <Examples>
        <p>
          <b>Während des Kochens</b> telefonierte David
          ständig
        </p>
        <Translation>
          David always phoned while cooking
        </Translation>
      </Examples>
    </Example>
    <p>
      <i>Wegen</i> means <i>because of</i> or <u>due to</u>
    </p>
    <Example>
      <Examples>
        <p>
          <b>Wegen des schlechsten Wetters</b> sind wir zu
          Hause geblieben
        </p>
        <Translation>
          Because of the bad weather we stayed home
          <Popup
            content="The same as with <i>aber<i> with <i>oder</i>you don't need to<br />
            alter the order of the phrace. After <i>oder</i> we place<br />
            the subject and the verb plus complements"
          />
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      The conjunctions <i>und</i> and <i>denn</i>
    </h2>
    <p>
      <i>Und</i> means <u>and</u> and it allows you to
      combine two or more ideas
    </p>
    <Example>
      <Examples>
        <p>
          Tom arbeitet in Berlin <b>und</b> Luzie in Paris
        </p>
        <Translation>
          Tom works in Berlin and Luzie in Paris
        </Translation>
      </Examples>
    </Example>
    <p>
      The conjuction <i>und</i> <u>does not change</u> the
      word order of the sentence
    </p>
    <Example>
      <Examples>
        <p>
          Die Kinder speieln <b>und</b> die Erwachsenen
          sehen einen Film
        </p>
        <Translation>
          The children play and the adults watch a film
        </Translation>
      </Examples>
    </Example>
    <p>
      The conjuction <i>denn</i> means <u>because</u> and it
      also does not change the word order of the sentence
    </p>
    <Example>
      <Examples>
        <p>
          Ich darf nicht schlafen, <b>denn</b> ich muss
          lernen
        </p>
        <Translation>
          I cannot sleep because I have to study
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Sentences with <i>dass</i>
    </h2>
    <p>
      <i>Dass</i> means <u>that</u>. In the part of the
      sentence that includes it, the conjugated verb
      occupies the <u>last</u> position
    </p>
    <Example>
      <Examples>
        <p>Es ist schade, dass ihr schon gehen müsst</p>
        <Translation>
          It is a shame that you have to go
        </Translation>
      </Examples>
    </Example>
    <p>
      As you will see in the following examples, <i>dass</i>{' '}
      is also used in the expression in an <u>indirect</u>{' '}
      way:
    </p>
    <Example>
      <Examples>
        <p>Sie sagt, dass das Wetter nicht gut ist</p>
        <Translation>
          She says that the weather is not good
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Interrogatives used as conjugations
    </h2>
    <p>
      When an interrogative is used as a subordinate clause,
      the relevant interrogative pronoun such as{' '}
      <u>wer, was, wo, wann, wie</u> serves as a conjugation
    </p>
    <Example>
      <Examples>
        <p>
          Ich weiß nicht, <b>wer</b> draußen ist
        </p>
        <Translation>
          I do not know who is outside
        </Translation>
      </Examples>
    </Example>
    <p>
      A comma is <u>placed</u> before the interrogative
      pronoun
    </p>
    <Example>
      <Examples>
        <p>
          Ich weiß nicht, <b>wo</b> der Bodensee ist
        </p>
        <Translation>
          I do not know where Lake Constance is
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Subordinating conjunctions <i>weil</i> and{' '}
      <i>obwohl</i>
    </h2>
    <p>
      <i>Weil</i> expresses the reason <b>why</b> something
      occurs and means
      <u>because</u>
    </p>
    <Example>
      <Examples>
        <p>
          Warum hast du einen Laptop gekauft? <b>weil</b>{' '}
          och viel <b>reise</b>
        </p>
        <Translation>
          Why did you buy a laptop? Because I travel a lot
        </Translation>
      </Examples>
    </Example>
    <p>
      In subordinate phraces with <i>weil</i> the verb
      should always come at the <u>end</u>
    </p>
    <Example>
      <Examples>
        <p>
          Er arbeitet nicht, <b>weil</b> er krank <b>ist</b>
        </p>
        <Translation>
          He is not working because he is ill
        </Translation>
      </Examples>
    </Example>
    <p>
      <i>Obwohl</i> expresses a contrast or the reason
      soemthing should not occur. It means{' '}
      <u>even though, although</u>
    </p>
    <Example>
      <Examples>
        <p>
          Ich komme mit dir, <b>obwohl</b> ich sehr müde{' '}
          <b>bin</b>
        </p>
        <Translation>
          I will come with you, even though I am very tired
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Subordinating conjunctions <i>damit</i> and{' '}
      <i>wenn</i>
    </h2>
    <p>
      <i>Damit</i> explains the reason <b>why</b> something
      is done in a certain way. A close tranlsation in
      English would be <u>so, so that</u>
    </p>
    <Example>
      <Examples>
        <p>
          Ich habe die Zeitschrift gekauft, <b>damit</b> ich
          sie lesen kann
        </p>
        <Translation>
          I bought the magazine so I can read it
        </Translation>
      </Examples>
    </Example>
    <p>
      You use <i>wenn</i> to indicate a condition for
      something to happen. It means <i>if</i> and it also
      has the temporal meaning <i>when</i>
    </p>
    <Example>
      <Examples>
        <p>
          Morgen werde ich ausgehen, <b>wenn</b> er nicht
          kommt
        </p>
        <Translation>
          I will go out tomorrow if he does not come
        </Translation>
      </Examples>
    </Example>
  </Description>
);

export default Conjugation;
