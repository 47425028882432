import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Adjective from 'components/content/grammar/Adjective';
import Conjugation from 'components/content/grammar/Conjugation';
import Dative from 'components/content/grammar/Dative';
import Future from 'components/content/grammar/Future';
import Genitive from 'components/content/grammar/Genitive';
import Passive from 'components/content/grammar/Passive';
import Perfect from 'components/content/grammar/Perfect';
import Plural from 'components/content/grammar/Plural';
import Preposition from 'components/content/grammar/Preposition';
import Present from 'components/content/grammar/Present';
import Preterite from 'components/content/grammar/Preterite';
import Pronoun from 'components/content/grammar/Pronoun';

import AdjectiveEndings from 'components/table/AdjectiveEndings';
import Articles from 'components/table/Articles';
import CoordinatingConjunctions from 'components/table/CoordinatingConjunctions';
import Interrogatives from 'components/table/Interrogatives';
import ModalVerbs from 'components/table/ModalVerbs';
import Prepositions from 'components/table/Prepositions';
import SubordinatingConjunctions from 'components/table/SubordinatingConjunctions';
import WeakVerbs from 'components/table/WeakVerbs';

import HomePage from 'containers/page/HomePage';
import GrammarPage from 'containers/page/GrammarPage';
import ConversationPage from 'containers/page/ConversationPage';
import ReferencePage from 'containers/page/ReferencePage';
import TermsAndConditionsPage from 'containers/page/TermsAndConditionsPage';
import PrivacyPolicyPage from 'containers/page/PrivacyPolicyPage';

const AdjectivePage = () => (
  <GrammarPage
    courseName="adjective"
    courseContent={Adjective()}
  />
);

const ConjugationPage = () => (
  <GrammarPage
    courseName="conjugation"
    courseContent={Conjugation()}
  />
);

const DativePage = () => (
  <GrammarPage
    courseName="dative"
    courseContent={Dative()}
  />
);

const FuturePage = () => (
  <GrammarPage
    courseName="future"
    courseContent={Future()}
  />
);

const PassivePage = () => (
  <GrammarPage
    courseName="passive"
    courseContent={Passive()}
  />
);

const PerfectPage = () => (
  <GrammarPage
    courseName="perfect"
    courseContent={Perfect()}
  />
);

const PresentPage = () => (
  <GrammarPage
    courseName="present"
    courseContent={Present()}
  />
);

const PreteritePage = () => (
  <GrammarPage
    courseName="preterite"
    courseContent={Preterite()}
  />
);

const PrepositionPage = () => (
  <GrammarPage
    courseName="preposition"
    courseContent={Preposition()}
  />
);

const PronounPage = () => (
  <GrammarPage
    courseName="pronoun"
    courseContent={Pronoun()}
  />
);

const GenitivePage = () => (
  <GrammarPage
    courseName="genitive"
    courseContent={Genitive()}
  />
);

const PluralPage = () => (
  <GrammarPage
    courseName="plural"
    courseContent={Plural()}
  />
);

const ArticlesPage = () => (
  <ReferencePage title="Articles" content={Articles()} />
);

const AdjectiveEndingsPage = () => (
  <ReferencePage
    title="Adjective Endings"
    content={AdjectiveEndings()}
  />
);

const InterrogativesPage = () => (
  <ReferencePage
    title="Interrogatives"
    content={Interrogatives()}
  />
);

const PrepositionsPage = () => (
  <ReferencePage
    title="Prepositions"
    content={Prepositions()}
  />
);

const CoordinatingConjunctionsPage = () => (
  <ReferencePage
    title="Coordinating Conjunctions"
    description="These ocupy position 0 and leave the verb position the same as in the preceding clause."
    content={CoordinatingConjunctions()}
  />
);

const ModalVerbsPage = () => (
  <ReferencePage
    title="Modal Verbs"
    content={ModalVerbs()}
  />
);

const SubordinatingConjunctionsPage = () => (
  <ReferencePage
    title="Subordinating Conjunctions"
    description="These send the conjugated verb to the end of the
    clause."
    content={SubordinatingConjunctions()}
  />
);

const WeakVerbsPage = () => (
  <ReferencePage title="Weak Verbs" content={WeakVerbs()} />
);

// const PeoplePage = () => (
//   <ConversationPage courseName="people" />
// );

const AppearancePage = () => (
  <ConversationPage courseName="appearance" />
);

const Routes = () => (
  <div>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route
        exact
        path="/grammar"
        component={GrammarPage}
      />
      <Route
        exact
        path="/grammar/adjective"
        component={AdjectivePage}
      />
      <Route
        exact
        path="/grammar/conjugation"
        component={ConjugationPage}
      />
      <Route
        exact
        path="/grammar/dative"
        component={DativePage}
      />
      <Route
        exact
        path="/grammar/future"
        component={FuturePage}
      />
      <Route
        exact
        path="/grammar/passive"
        component={PassivePage}
      />
      <Route
        exact
        path="/grammar/plural"
        component={PluralPage}
      />
      <Route
        exact
        path="/grammar/perfect"
        component={PerfectPage}
      />
      <Route
        exact
        path="/grammar/present"
        component={PresentPage}
      />
      <Route
        exact
        path="/grammar/preterite"
        component={PreteritePage}
      />
      <Route
        exact
        path="/grammar/preposition"
        component={PrepositionPage}
      />
      <Route
        exact
        path="/grammar/pronoun"
        component={PronounPage}
      />
      <Route
        exact
        path="/grammar/genitive"
        component={GenitivePage}
      />
      <Route
        exact
        path="/reference"
        component={ReferencePage}
      />
      <Route
        exact
        path="/reference/articles"
        component={ArticlesPage}
      />
      <Route
        exact
        path="/reference/adjective-endings"
        component={AdjectiveEndingsPage}
      />
      <Route
        exact
        path="/reference/interrogatives"
        component={InterrogativesPage}
      />
      <Route
        exact
        path="/reference/prepositions"
        component={PrepositionsPage}
      />
      <Route
        exact
        path="/reference/coordinating-conjunctions"
        component={CoordinatingConjunctionsPage}
      />
      <Route
        exact
        path="/reference/subordinating-conjunctions"
        component={SubordinatingConjunctionsPage}
      />
      <Route
        exact
        path="/reference/modal-verbs"
        component={ModalVerbsPage}
      />
      <Route
        exact
        path="/reference/weak-verbs"
        component={WeakVerbsPage}
      />
      {/* <Route
        exact
        path="/conversation/people"
        component={PeoplePage}
      /> */}
      <Route
        exact
        path="/conversation/appearance"
        component={AppearancePage}
      />
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditionsPage}
      />
      <Route
        exact
        path="/privacy-policy"
        component={PrivacyPolicyPage}
      />
    </Switch>
  </div>
);

export default Routes;
