import { createGlobalStyle } from 'styled-components';
import COLORS from 'constants/colors';

export const GlobalStyle = createGlobalStyle`
    :root {
      font-size: 10px;
      overflow: hidden;
    }

    /* Semantic UI */

    .ui.celled.table,
    .ui.table {
      font-size: 1.4rem;
    }

    .ui.table thead th {
      text-align: center;
    }

    .ui.table.example {
      font-weight: normal;
    }

    .ui.header {
      background: ${({ theme: { theme } }) =>
        theme.background} !important;
      color: ${({ theme: { theme } }) =>
        theme.color} !important;
    }

    .ui.menu {
      font-size: 1.6rem !important;
      position:relative;
    }

    .ui.vertical.tabular.menu .active.item {
      background: ${({ theme: { theme } }) =>
        theme.background} !important;
      border: 0;
      color: ${({ theme: { theme } }) =>
        theme.color} !important;
    }

    .mobile.only.narrow.row {
      position: fixed;
      width: 100vw;
      z-index: 3;
    }

    .footer .ui.inverted.header {
      background: ${COLORS.DARK_JUNGLE_GREEN} !important;
      color: ${COLORS.WHITE} !important;
    }

    .ui.inverted.navbar.menu {
      width: 104vw;
    }

    .ui.vertical.navbar.menu {
      position: fixed;
      top: 4rem;
      width: 104vw;
      z-index: 5;
    }

    .ui.vertical.menu {
      position: absolute;
    }

    .ui.vertical.menu .dropdown.item:not(.upward) .menu {
      left: -1rem;
      padding-left: 0.6rem;
      position: relative;
      top: 1.5rem!important;
      width: 104vw;
    }

    /* React-Tabs */

    .react-tabs {
      background: ${({ theme: { theme } }) =>
        theme.background};
      color: ${({ theme: { theme } }) => theme.color};
    }

    .react-tabs__tab {
      margin-bottom: -0.05rem !important;
      padding: 0.5rem 1.2rem 0.8rem;
    }

    .react-tabs__tab--selected {
      background: ${({ theme: { theme } }) =>
        theme.background} !important;
      color: ${({ theme: { theme } }) =>
        theme.color} !important;
    }

    .react-tabs__tab-list {
      background-color: ${({ theme: { theme } }) =>
        theme.background};
      color: ${({ theme: { theme } }) => theme.color};
      margin-bottom: 1rem;
      top: 9.5rem;
      z-index: 2;
    }

    .react-tabs__tab-panel--selected {
      margin: 2rem 0;  
    }
`;

export const lightTheme = {
  background: COLORS.WHITE,
  color: COLORS.DARK_JUNGLE_GREEN,
  name: 'light',
};

export const darkTheme = {
  background: COLORS.DARK_JUNGLE_GREEN,
  color: COLORS.WHITE,
  name: 'dark',
};
