import React from 'react';

const Articles = () => (
  <table className="ui celled table">
    <thead>
      <tr><th colSpan={5}>Definite articles <span className="example">(the)</span></th></tr>
    </thead>
    <tbody>
      <tr>
        <td>Case</td>
        <td>Masculine</td>
        <td>Feminine</td>
        <td>Neuter</td>
        <td>Plural</td>
      </tr>
      <tr>
        <td>Nominative</td>
        <td>der</td>
        <td>die</td>
        <td>das</td>
        <td>die</td>
      </tr>
      <tr>
        <td>Accusative</td>
        <td>den</td>
        <td>die</td>
        <td>das</td>
        <td>die</td>
      </tr>
      <tr>
        <td>Dative</td>
        <td>dem</td>
        <td>der</td>
        <td>dem</td>
        <td>den</td>
      </tr>
      <tr>
        <td>Genitive</td>
        <td>des</td>
        <td>der</td>
        <td>des</td>
        <td>der</td>
      </tr>
    </tbody>
    <thead>
      <tr><th colSpan={5}>Indefinite articles <span className="example">a/an</span></th></tr>
    </thead>
    <tbody>
      <tr>
        <td>Case</td>
        <td>Masculine</td>
        <td>Feminine</td>
        <td>Neuter</td>
        <td>Plural</td>
      </tr>
      <tr>
        <td>Nominative</td>
        <td>ein</td>
        <td>eine</td>
        <td>ein</td>
        <td>keine*</td>
      </tr>
      <tr>
        <td>Accusative</td>
        <td>einen</td>
        <td>eine</td>
        <td>ein</td>
        <td>keine*</td>
      </tr>
      <tr>
        <td>Dative</td>
        <td>einem</td>
        <td>einer</td>
        <td>einem</td>
        <td>keinen*</td>
      </tr>
      <tr>
        <td>Genitive</td>
        <td>eines</td>
        <td>einer</td>
        <td>eines</td>
        <td>keiner*</td>
      </tr>
    </tbody>
  </table>
);

export default Articles;
