// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import toPascalCase from 'to-pascal-case';
import styled from 'styled-components';
import Breadcrumb from 'components/menu/Breadcrumb';
import Task from 'components/task/Task';
import * as actions from 'actions/course';
import { Heading, Wrapper } from 'styles/content';
import 'react-tabs/style/react-tabs.css';

const ContentWrapper = styled.div`
  margin-top: 1rem;
`;

const TaskWrapper = styled.div`
  text-align: center;
`;

type Props = {
  breadcrumbLinks: Array<Object>,
  content: Object,
  courseName: string,
  courseType: string,
  requestCourse: Function,
  course: {
    course: Object,
    tasks: Array<Object>,
  },
};

type State = {
  currentTask: {},
  number: number,
};

class Content extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { currentTask: {}, number: 0 };
  }

  componentDidMount() {
    const {
      requestCourse,
      courseName,
      courseType,
    } = this.props;

    requestCourse(courseName, courseType);
  }

  handleNextClick = (evt, task) => {
    if (task) {
      this.setState({
        currentTask: task,
        number: this.state.number + 1,
      });
      this.renderTask(task);
    }
  };

  handlePreviousClick = (evt, task) => {
    if (task) {
      this.setState({
        currentTask: task,
        number: this.state.number - 1,
      });
      this.renderTask(task);
    }
  };

  renderTask(nextTask, previousTask, total = 0) {
    const {
      id,
      question,
      answer,
      hint,
      isFormal,
      isPlural,
      tips,
    } = nextTask;
    const number = this.state.number;

    return (
      <TaskWrapper>
        <p>
          Question: {number + 1} / {total}
        </p>
        <Task
          id={id}
          key={id}
          question={question}
          answer={answer}
          isFirst={number === 0}
          isLast={number === total - 1}
          hint={hint}
          isFormal={isFormal}
          isPlural={isPlural}
          tips={tips}
          nextTask={nextTask}
          previousTask={previousTask}
          handleNextClick={this.handleNextClick}
          handlePreviousClick={this.handlePreviousClick}
        />
      </TaskWrapper>
    );
  }

  renderDescription(description) {
    /* eslint-disable */
    return (
      description &&
      description.map(({ item, index }) => (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))
    );
    /* eslint-enable */
  }

  render() {
    const {
      course,
      courseName,
      content,
      breadcrumbLinks,
    } = this.props;
    const { description, tasks } = course.course;
    const nextTask = tasks && tasks[this.state.number];
    const previousTask =
      tasks && tasks[this.state.number - 1];
    const total = tasks.length;

    return (
      <Wrapper className="ui wide column">
        <Heading>
          <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
        </Heading>
        <ContentWrapper withBreadcrumbs>
          <Tabs>
            <TabList>
              <Tab>Description</Tab>
              <Tab>Practice</Tab>
            </TabList>
            <TabPanel>
              {this.renderDescription(description)}
              {content}
            </TabPanel>
            <TabPanel>
              <h2 className="ui header">
                {toPascalCase(courseName)}
              </h2>
              {nextTask &&
                this.renderTask(
                  nextTask,
                  previousTask,
                  total
                )}
            </TabPanel>
          </Tabs>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actions, dispatch)
)(Content);
