import React from 'react';
import styled from 'styled-components';
import { ThemeConsumer } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.span`
  flex: 1;
  line-height: 1.4;
`;

const Select = styled.select`
  border: 0.1rem solid ${({ theme }) => theme.light};
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.light};
  border-radius: 0.28571429rem;
  flex-direction: end;
  margin-left: 1.5rem;
  padding: 0 1rem;
  z-index: 6;
`;

const ThemeSelector = ({
  handleThemeChange,
}: {
  handleThemeChange: () => {},
  theme: string,
}) => (
  <Wrapper>
    <Label>Theme</Label>
    <ThemeConsumer>
      {({ theme }) => (
        <Select
          className="ui"
          onChange={evt => handleThemeChange(evt)}
          value={
            theme.name === 'light'
              ? 'lightTheme'
              : 'darkTheme'
          }>
          <option value="lightTheme">Light</option>
          <option value="darkTheme">Dark</option>
        </Select>
      )}
    </ThemeConsumer>
  </Wrapper>
);

export default ThemeSelector;
