import React from 'react';

const Prepositions = () => (
  <table className="ui celled table">
    <thead>
      <tr>
        <th>Accusative</th>
        <th>Meaning</th>
        <th>Dative</th>
        <th>Meaning</th>
        <th>Accusative/Dative</th>
        <th>Meaning</th>
        <th>Genitive</th>
        <th>Meaning</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>bis</td>
        <td>until</td>
        <td>aus</td>
        <td>out of, from</td>
        <td>an</td>
        <td>up to, over to</td>
        <td>statt</td>
        <td>instead of</td>
      </tr>
      <tr>
        <td>entlang</td>
        <td>along</td>
        <td>außer</td>
        <td>except for</td>
        <td>auf</td>
        <td>onto</td>
        <td>außerhalb</td>
        <td>outside</td>
      </tr>
      <tr>
        <td>durch</td>
        <td>through</td>
        <td>bei</td>
        <td>at</td>
        <td>in</td>
        <td>into</td>
        <td>innerhalb</td>
        <td>within</td>
      </tr>
      <tr>
        <td>ohne</td>
        <td>without</td>
        <td>mit</td>
        <td>with</td>
        <td>vor</td>
        <td>in front of</td>
        <td>trotz</td>
        <td>despite</td>
      </tr>
      <tr>
        <td>gegen</td>
        <td>against</td>
        <td>nach</td>
        <td>after</td>
        <td>hinter</td>
        <td>behind</td>
        <td>während</td>
        <td>while</td>
      </tr>
      <tr>
        <td>wider</td>
        <td>against</td>
        <td>seit</td>
        <td>since</td>
        <td>über</td>
        <td>about</td>
        <td>wegen</td>
        <td>because of</td>
      </tr>
      <tr>
        <td>um</td>
        <td>around</td>
        <td>von</td>
        <td>from, of</td>
        <td>unter</td>
        <td>under</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>für</td>
        <td>for</td>
        <td>zu</td>
        <td>to</td>
        <td>neben</td>
        <td>next to, beside</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>zwischen</td>
        <td>between</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
);

export default Prepositions;
