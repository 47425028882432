import React from 'react';
import Popup from 'components/shared/Popup';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Future = () => (
  <Description>
    <h2 className="ui header">
      Using <i>weden</i> for the future
    </h2>
    <p>
      As long as you know how to conjugate <i>werden</i>,
      you can form future tense. Just use the appropriate
      verb form of <i>werden</i> + the infinitive of the
      main verb
    </p>
    <Example>
      <Examples>
        <p>
          Ich <b>werde</b> morgen <b>einkaufen</b>
        </p>
        <Translation>
          I will go shopping tomorrow
        </Translation>
      </Examples>
      <Examples>
        <p>
          Du <b>wirst</b> Abendessen <b>machen</b>
        </p>
        <Translation>You will make dinner</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Talking about the future with the <i>Präsens</i>
    </h2>
    <p>
      A more common way to express the future in German is
      with the <u>present tense</u>
    </p>
    <Example>
      <Examples>
        <p>
          Morgen <b>esse</b> ich Fleisch
          <Popup
            content="The equivalent of this in English would be the<br/>
              <i>present progressive</i>, which is often<br/>
              used to talk about the future.<br/>
              There is however no exact equivalent of the<br/>
              English <i>going to</i> future from in German"
          />
        </p>
        <Translation>I am eating meat tomorrow</Translation>
      </Examples>
      <Examples>
        <p>
          Ich <b>fahre</b> nächste Woche nach Dessau
          <Popup
            content="It's usually easy to identify when a sentence<br />
              is using this sort of tense, as it often includes<br />
              some sort of temporal signifier like <i>morgen</i> (tomorrow)"
          />
        </p>
        <Translation>
          I am driving to Dessau next week
        </Translation>
      </Examples>
    </Example>
  </Description>
);

export default Future;
