import React from 'react';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Passive = () => (
  <Description>
    <h2 className="ui header">
      The <i>Passiv</i>
    </h2>
    <p>
      As in English, you use the <i>Passiv</i> (passive
      voice) in German if you don&#8217;t know who performed
      an action or if knowing who performed it is{' '}
      <u>irrelevant</u>
    </p>
    <Example>
      <Examples>
        <p>
          Die Firma <b>wurde</b> schon im Jahr 1900{' '}
          <b>gegründet</b>
        </p>
        <Translation>
          The company was already founded in 1900
        </Translation>
      </Examples>
      <Examples>
        <p>
          Der Blinde <b>wird gefrührt</b>
        </p>
        <Translation>
          The blind (man) is being guided
        </Translation>
      </Examples>
    </Example>
    <p>
      The <i>Passiv</i> is <u>often</u> used in general or
      universal statements and can be formed in every tense
    </p>
    <Example>
      <Examples>
        <p>
          In Deutschland <b>werden</b> viele Autos{' '}
          <b>produziert</b>
        </p>
        <Translation>
          In Germany, they produce a lot of cars (lit. a lot
          of cars are produced)
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      How to form the <i>Passiv</i>
    </h2>
    <p>
      The <i>Passiv</i> is formed with a form of{' '}
      <u>werden</u> and the past participle
    </p>
    <Example>
      <Examples>
        <p>
          Der Blinde <b>wird geführt</b>
        </p>
        <Translation>
          The blind (man) is being guided
        </Translation>
      </Examples>
    </Example>
    <p>
      The past participle is always <u>at the end</u> of the
      sentence
    </p>
    <Example>
      <Examples>
        <p>
          Die Brücke wurde nach dem Krieg ge<b>baut</b>
        </p>
        <Translation>
          The bridge wsa built after the war
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">By whom is it done...?</h2>
    <p>
      If you want to mention the person or thing by whom an
      action is performed (agent), you can use <u>von</u>{' '}
      plus dative
    </p>
    <Example>
      <Examples>
        <p>
          Dier Blinde wird <b>von einem Blindenhund</b>{' '}
          geführt
        </p>
        <Translation>
          The blind(man) is guided by a guide dog
        </Translation>
      </Examples>
      <Examples>
        <p>
          Die Nationalhymne wurde <b>von Hayden</b>{' '}
          komponiert, nicht <b>von Mozart</b>
        </p>
        <Translation>
          The National anthem was composed by Haydn, not by
          Mozart
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Questions in the <i>Passiv</i>
    </h2>
    <p>
      Questions in the <i>Passiv</i> work the same way as
      other questions in German. The conjugated verb form of{' '}
      <i>werden</i> comes first followed by the subject. The
      past participle is at very <u>end</u> of the question
    </p>
    <Example>
      <Examples>
        <p>
          Wann <b>wurde</b> die deutsche Nationalhymne{' '}
          <b>komponiert</b>
        </p>
        <Translation>
          When was the German national anthem composed?
        </Translation>
      </Examples>
      <Examples>
        <p>
          <b>Wurde</b> die Nationalhymne von Haydn{' '}
          <b>komponiert</b>?
        </p>
        <Translation>
          Was the national anthem was composed by Haydn?
        </Translation>
      </Examples>
    </Example>
  </Description>
);

export default Passive;
