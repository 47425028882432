import React from 'react';

const ModalVerbs = () => (
  <table className="ui celled table">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th>müssen</th>
        <th>können</th>
        <th>dürfen</th>
        <th>sollen</th>
        <th>wollen</th>
        <th>mögen</th>
        <th>möchten</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colSpan={8}>Present <span className="example">(Präsens)</span></td>
      </tr>
      <tr>
        <td>ich</td>
        <td>muss</td>
        <td>kann</td>
        <td>darf</td>
        <td>soll</td>
        <td>will</td>
        <td>mag</td>
        <td>möchte</td>
      </tr>
      <tr>
        <td>du</td>
        <td>musst</td>
        <td>kannst</td>
        <td>darfst</td>
        <td>sollst</td>
        <td>willst</td>
        <td>magst</td>
        <td>möchtest</td>
      </tr>
      <tr>
        <td>er/sie/es</td>
        <td>muss</td>
        <td>kann</td>
        <td>darf</td>
        <td>soll</td>
        <td>will</td>
        <td>mag</td>
        <td>möchte</td>
      </tr>
      <tr>
        <td>wir</td>
        <td>müssen</td>
        <td>können</td>
        <td>dürfen</td>
        <td>sollen</td>
        <td>wollen</td>
        <td>mögen</td>
        <td>möchten</td>
      </tr>
      <tr>
        <td>ihr</td>
        <td>müsst</td>
        <td>könnt</td>
        <td>dürft</td>
        <td>sollt</td>
        <td>wollt</td>
        <td>mögt</td>
        <td>möchtet</td>
      </tr>
      <tr>
        <td>sie/Sie</td>
        <td>müssen</td>
        <td>können</td>
        <td>dürfen</td>
        <td>sollen</td>
        <td>wollen</td>
        <td>mögen</td>
        <td>möchten</td>
      </tr>
      <tr>
        <td colSpan={8}>Simple past <span className="example">(Präteritum)</span></td>
      </tr>
      <tr>
        <td>ich</td>
        <td>musste</td>
        <td>konnte</td>
        <td>durfte</td>
        <td>sollte</td>
        <td>wollte</td>
        <td>mochte</td>
        <td>wollte</td>
      </tr>
      <tr>
        <td>du</td>
        <td>musstest</td>
        <td>konntest</td>
        <td>durftest</td>
        <td>solltest</td>
        <td>wolltest</td>
        <td>mochtest</td>
        <td>wolltest</td>
      </tr>
      <tr>
        <td>er/sie/es</td>
        <td>musste</td>
        <td>konnte</td>
        <td>durfte</td>
        <td>sollte</td>
        <td>wollte</td>
        <td>mochte</td>
        <td>wollte</td>
      </tr>
      <tr>
        <td>wir</td>
        <td>mussten</td>
        <td>konnten</td>
        <td>durften</td>
        <td>sollten</td>
        <td>wollten</td>
        <td>mochten</td>
        <td>wollten</td>
      </tr>
      <tr>
        <td>ihr</td>
        <td>musstet</td>
        <td>konntet</td>
        <td>durftet</td>
        <td>solltet</td>
        <td>wolltet</td>
        <td>mochtet</td>
        <td>wolltet</td>
      </tr>
      <tr>
        <td>sie/Sie</td>
        <td>mussten</td>
        <td>konnten</td>
        <td>durften</td>
        <td>sollten</td>
        <td>wollten</td>
        <td>mochten</td>
        <td>wollten</td>
      </tr>
      <tr>
        <td colSpan={8}>Past Participle <span className="example">(Partizip II)</span></td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>gemusst</td>
        <td>gekonnt</td>
        <td>gedurft</td>
        <td>gesollt</td>
        <td>gewollt</td>
        <td>gemocht</td>
        <td>gewollt</td>
      </tr>
      <tr>
        <td colSpan={8}>Subjunctive II <span className="example">(Konjunktiv II)</span></td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>müsste</td>
        <td>könnt</td>
        <td>dürfte</td>
        <td>sollte</td>
        <td>wollte</td>
        <td>möchte</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
);

export default ModalVerbs;
