import React from 'react';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Pronoun = () => (
  <Description>
    <h2 className="ui header">Forming the plural</h2>
    <p>
      As you know, theer are different ways to form the
      plural in German. The most common is to add the
      endings <i>-(e)n</i> and <i>-e</i>. In some nouns, the
      vowels a, o and u then change into ä, ö and ü
      (especially in nouns with the plural ending <i>-e</i>
      ).
    </p>
    <Example>
      <Examples>
        <p>der Pilz - die Pilze, der Baum - die Bäume</p>
        <Translation>
          the mushroom - the mushrooms, the tree - the trees
        </Translation>
      </Examples>
    </Example>
    <p>
      Abbreviations, foreign words, and nouns ending in{' '}
      <i>a, i, o</i> or <i>u</i>
      receive the ending -s in the plural
    </p>
    <Example>
      <Examples>
        <p>der App - die Apps, das Auto - die Autos</p>
        <Translation>
          the app - the apps, the car - the cars
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      More ways of forming the plural
    </h2>
    <p>
      Many neuter and some masculine nouns from the plural
      with the ending <u>-er</u>. In these nouns, a, o and u
      then often becomes ä, ö and ü
    </p>
    <Example>
      <Examples>
        <p>der Wald - die Wälder, das Bild - die Bilder</p>
        <Translation>
          the forest - the forests, the image - the images
        </Translation>
      </Examples>
    </Example>
    <p>
      Neuter and masculine nouns ending in{' '}
      <i>-lein, -chen, -er, -en</i> and <i>-el</i>
      don not have a plural ending but the <u>vowels</u> may
      change
    </p>
    <Example>
      <Examples>
        <p>das Mädchen - die Mädchen</p>
        <Translation>the girl - the girls</Translation>
      </Examples>
    </Example>
    <Example>
      <Examples>
        <p>der Garten - die Gärten</p>
        <Translation>the garden - the gardens</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      <i>Es gibt...</i> + accusative
    </h2>
    <p>
      The German expression <i>es gibt</i> is a set
      expression which always remains in the <u>third</u>{' '}
      person singular
    </p>
    <Example>
      <Examples>
        <p>
          <b>Es gibt</b> einen schönen Wanderweg hier -{' '}
          <b>Es gibt</b> schöne Wanderwege hier
        </p>
        <Translation>
          There is a beautiful hiking trail here - There are
          beautiful hiking trails here
        </Translation>
      </Examples>
    </Example>
    <p>
      As you may have already noticed, es gibt always
      requires accusative
    </p>
    <Example>
      <Examples>
        <p>
          Die Wanderer sagen, es gibt <b>einen Bären</b> im
          Wald!
        </p>
        <Translation>
          The hikers say that there is a bear in the forest!
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">The accusative</h2>
    <p>
      As you already know, the use of the accusative depends
      on the <u>verb</u> or the
      <u>preposition</u>. Some verbs that require the
      accusative are <i>haben</i> or the (to have),{' '}
      <i>sehen</i> (to see), <i>machen</i> (to make) and{' '}
      <i>essen</i> (to eat)
    </p>
    <Example>
      <Examples>
        <p>
          Sie machen <b>eine Pause</b> und essen{' '}
          <b>einen Apfel</b>.
        </p>
        <Translation>
          They are having a break and eating an apple
        </Translation>
      </Examples>
    </Example>
    <Example>
      <Examples>
        <p>
          Siehst du <b>die Wildschweine</b> und{' '}
          <b>den Hirsch</b> da vorne?
        </p>
        <Translation>
          Do you see the wild boars and the stag up ahead?
        </Translation>
      </Examples>
    </Example>
    <p>
      The following prepositions <u>always</u> require
      accusative: <i>für, durch, gegen, ohne</i>
      and <i>um</i>
    </p>
    <Example>
      <Examples>
        <p>
          Wir sind durch <b>einen wunderschönen Wald</b>{' '}
          gewendert
        </p>
        <Translation>
          We wandered through a beautiful forest
        </Translation>
      </Examples>
    </Example>
  </Description>
);

export default Pronoun;
