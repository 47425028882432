import React from 'react';
import styled from 'styled-components';
import Grammar from 'components/links/Grammar';
import Reference from 'components/links/Reference';
import Conversation from 'components/links/Conversation';
import Logo from 'components/shared/Logo';

const className = 'ui inverted link list';

const Wrapper = styled.div.attrs({
  className: 'ui inverted vertical segment footer',
})`
  padding: 2rem 3rem !important;
  margin-top: 4rem !important;
  width: 100%;
  font-size: 1.4rem !important;

  .header,
  .item {
    text-align: left !important;
  }
`;

const Footer = () => (
  <Wrapper>
    <div className="ui center aligned container">
      <div className="ui stackable inverted divided grid">
        <div className="three wide column">
          <Grammar className={className} />
        </div>
        <div className="three wide column">
          <Reference className={className} />
        </div>
        <div className="three wide column">
          <Conversation className={className} />
        </div>
        <div className="three wide column">
          <h4 className="ui inverted header">More</h4>
          <p className="item">And much more coming soon.</p>
        </div>
      </div>
      <div className="ui inverted section divider" />
      <Logo />
      <div className="ui horizontal inverted small divided link list">
        <a className="item" href="/sitemap.xml">
          Site Map
        </a>
        <a className="item" href="/">
          Contact Us
        </a>
        <a className="item" href="/terms-and-conditions">
          Terms and Conditions
        </a>
        <a className="item" href="/privacy-policy">
          Privacy Policy
        </a>
      </div>
    </div>
  </Wrapper>
);

export default Footer;
