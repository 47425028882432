import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({
  breadcrumbLinks,
}: {
  breadcrumbLinks: Array<Object>,
}) => {
  const breadcrumbs = breadcrumbLinks.map(
    ({ name, isActive, link }, index) => {
      return isActive ? (
        <span key={index}>
          <Link to={link} className="active section">
            {name}
          </Link>
        </span>
      ) : (
        <span key={index}>
          <Link to={link} className="section">
            {name}
          </Link>
          <i className="right angle icon divider" />
        </span>
      );
    }
  );

  return <div className="ui breadcrumb">{breadcrumbs}</div>;
};

export default Breadcrumb;
