const processResponse = (response) => {
  const { status, statusText } = response;

  if (status >= 200 && status <= 400) {
    return response.json();
  } else {
    throw new Error(statusText);
  }
};

export default processResponse;
