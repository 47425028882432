import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import CharKey from 'components/task/CharKey';

const Wrapper = styled.span`
  margin-top: 12rem;

  .off {
    opacity: 0.4;
  }
`;

const CharKeys = ({
  isCharKeysOn,
  handleCharKeysToggle,
}: {
  isCharKeysOn: boolean,
  handleCharKeysToggle: SyntheticEvent<HTMLInputElement>,
}) => (
  <Wrapper>
    <div>
      <span>short-cut keys </span>
      <span>
        {isCharKeysOn ? 'on' : 'off'}
        &nbsp;
      </span>
      <input
        type="checkbox"
        checked={isCharKeysOn}
        onChange={() => handleCharKeysToggle()}
      />
    </div>
    <div className={cx({ off: !isCharKeysOn })}>
      <CharKey char="ä" charKey="@" />
      <CharKey char="ö" charKey="#" />
      <CharKey char="ü" charKey="$" />
      <CharKey char="ß" charKey="%" />
    </div>
  </Wrapper>
);

export default CharKeys;
