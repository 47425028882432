import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { init } from 'utils/analytics';
import { useLocalStorage } from 'utils/localStorage';
import {
  GlobalStyle,
  lightTheme,
  darkTheme,
} from 'theme/globalStyle';
import Header from 'components/page/Header';
import Main from 'components/page/Main';
import Footer from 'components/page/Footer';
import * as actions from 'actions/course';

import 'semantic-ui-css/semantic.min.css';

const Wrapper = styled.div`
  background-color: ${({ theme: { theme } }) =>
    theme.background};
  color: ${({ theme: { theme } }) => theme.color};
`;

function App() {
  const [storage, setStorage] = useLocalStorage(
    'theme',
    {}
  );
  const [theme, setTheme] = useState({
    theme: storage.theme || lightTheme,
  });

  useEffect(() => {
    setStorage(theme);
  }, [setStorage, theme]);

  const handleThemeChange = evt => {
    const theme =
      evt.target.value === 'lightTheme'
        ? lightTheme
        : darkTheme;
    setTheme({ theme });
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper className="ui grid">
        {init()}
        <GlobalStyle />
        <Header handleThemeChange={handleThemeChange} />
        <Main />
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actions, dispatch)
)(App);
