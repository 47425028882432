import React from 'react';

const Reference = ({
  className = '',
}: {
  className: string,
}) => (
  <div className={className}>
    <a className="item" href="/reference/articles">
      Articles
    </a>
    <a className="item" href="/reference/adjective-endings">
      Adjective Endings
    </a>
    <a className="item" href="/reference/interrogatives">
      Interogatives
    </a>
    <a className="item" href="/reference/prepositions">
      Prepositions
    </a>
    <a
      className="item"
      href="/reference/coordinating-conjunctions">
      Coordinating Conjunctions
    </a>
    <a
      className="item"
      href="/reference/subordinating-conjunctions">
      Subordinating Conjunctions
    </a>
    <a className="item" href="/reference/modal-verbs">
      Modal Verbs
    </a>
    <a className="item" href="/reference/weak-verbs">
      Weak Verbs
    </a>
  </div>
);

export default Reference;
