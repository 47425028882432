import React from 'react';

const AdjectiveEndings = () => (
  <table className="ui celled table">
    <tbody>
      <tr>
        <td>&nbsp;</td>
        <td>Masculine</td>
        <td>Feminine</td>
        <td>Neuter</td>
        <td>Plural</td>
      </tr>
      <tr>
        <td colSpan={5}>Definite article <span className="example">(the)</span></td>
      </tr>
      <tr>
        <td>Nominative</td>
        <td>der klein<u>e</u> Mann</td>
        <td>die klein<u>e</u> Frau</td>
        <td>das klein<u>e</u> Kind</td>
        <td>die klein<u>e</u> Kinder</td>
      </tr>
      <tr>
        <td>Accusative</td>
        <td>den klein<u>en</u> Mann</td>
        <td>die klein<u>e</u> Frau</td>
        <td>das klein<u>e</u> Kind</td>
        <td>die klein<u>en</u> Kinder</td>
      </tr>
      <tr>
        <td>Dative</td>
        <td>den klein<u>en</u> Mann</td>
        <td>die klein<u>en</u> Frau</td>
        <td>das klein<u>en</u> Kind</td>
        <td>die klein<u>en</u> Kindern</td>
      </tr>
      <tr>
        <td>Gentive</td>
        <td>des klein<u>en</u> Mann</td>
        <td>der klein<u>en</u> Frau</td>
        <td>des klein<u>en</u> Kindes</td>
        <td>der klein<u>en</u> Kinder</td>
      </tr>
      <tr>
        <td colSpan={5}>Indefinite article <span className="example">(an)</span></td>
      </tr>
      <tr>
        <td>Nominative</td>
        <td>ein klein<u>er</u> Mann</td>
        <td>eine klein<u>e</u> Frau</td>
        <td>ein klein<u>es</u> Kind</td>
        <td>-</td>
      </tr>
      <tr>
        <td>Accusative</td>
        <td>einen klein<u>er</u> Mann</td>
        <td>eine klein<u>e</u> Frau</td>
        <td>ein klein<u>es</u> Kind</td>
        <td>-</td>
      </tr>
      <tr>
        <td>Dative</td>
        <td>einem klein<u>en</u> Mann</td>
        <td>einer klein<u>en</u> Frau</td>
        <td>einem klein<u>en</u> Kind</td>
        <td>-</td>
      </tr>
      <tr>
        <td>Genitive</td>
        <td>eines klein<u>en</u> Mann</td>
        <td>eine klein<u>e</u> Frau</td>
        <td>ein klein<u>es</u> Kind</td>
        <td>-</td>
      </tr>
    </tbody>
  </table>
);

export default AdjectiveEndings;
