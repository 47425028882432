import React from 'react';
import Popup from 'components/shared/Popup';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Preposition = () => (
  <Description>
    <h2 className="ui header">Prepositions</h2>
    <p>
      A preposition is a <b>little word</b> that brings a
      noun or pronoun into the sentence:
    </p>
    <p>
      <i>
        Zephyr <b>in</b> the sky <b>at</b> night I wonder,
        do my tears <b>of</b> mourning sink <b>beneath</b>{' '}
        the sun?
      </i>
    </p>
    <p>
      Prepositions don not affect word order in German, but
      they do determine the case of the noun or pronoun they
      bring into the sentence.
    </p>
    <p>
      A few words (e.g. seit, während) can be both
      conjunctions and prepositions. You have to determine
      their function by looking to see if they are
      connecting two phrases.
    </p>
    <p>
      <b>Conjunction:</b>&nbsp;
      <i>Ich esse SPAM, seit ich in Amerika bin</i>
    </p>
    <p>
      <b>Preposition</b>&nbsp;
      <i>Ich esse seit drei Stunden SPAM</i>
    </p>
    <p>
      English uses <b>after</b> and <b>before</b> as both
      conjunctions and prepositions, but German
      distinguishes the conjunctions <b>nachdem</b> and{' '}
      <b>bevor</b>
      from the prepositions <b>nach</b> and <b>vor</b>.
    </p>
    <p>
      Learn more about conjugations and prepositions&nbsp;
      <a
        href="https://www.lsa.umich.edu/german/hmr/Grammatik/Konjunktionen/Konjunktionen.html"
        rel="noopener noreferrer"
        target="_blank">
        here
      </a>
      .
    </p>
    <h2 className="ui header">
      Prepositions taking the genitive
    </h2>
    <p>
      Add the genitive endings to the indefinite articles,
      following the pattern of the definite articles
    </p>
    <p>Masculine:</p>
    <Example>
      <Examples>
        <p>
          D<b>es</b>, ei<u>nes</u>
        </p>
      </Examples>
    </Example>
    <p>Feminine:</p>
    <Example>
      <Examples>
        <p>
          D<b>er</b>, ei<u>ner</u>
        </p>
      </Examples>
    </Example>
    <p>Neuter:</p>
    <Example>
      <Examples>
        <p>
          D<b>es</b>, ei<u>nes</u>
        </p>
      </Examples>
    </Example>
    <h2 className="ui header">
      Prepositions that take the dative
    </h2>
    <p>
      Add the dative endings to the indefinite articles,
      following the pattern of the definite articles
    </p>
    <p>Masculine:</p>
    <Example>
      <Examples>
        <p>
          D<b>em</b>, ei<u>nem</u>
        </p>
      </Examples>
    </Example>
    <p>Feminine:</p>
    <Example>
      <Examples>
        <p>
          D<b>er</b>, ei<u>ner</u>
        </p>
      </Examples>
    </Example>
    <p>Neuter:</p>
    <Example>
      <Examples>
        <p>
          D<b>em</b>, ei<u>nem</u>
        </p>
      </Examples>
    </Example>
    <p>Plural:</p>
    <Example>
      <Examples>
        <p>
          D<b>en</b>, -
        </p>
      </Examples>
    </Example>
    <p>
      The prepositions which take the dative are:
      <u>aus, bei, mit, nach, seit, von, zu</u>
    </p>
    <Example>
      <Examples>
        <p>
          Ich komme <b>mit</b> mein<b>er</b> Freundin
        </p>
        <Translation>
          I am coming with my girlfriend
        </Translation>
        <p>
          Sie ist müde, sie kommt direkt <b>von</b> d
          <b>er</b> Party
        </p>
        <Translation>
          She is tired. She is coming straight from the
          party
        </Translation>
        <p>
          Er wohnt <b>bei</b> sein<b>er</b> Oma
        </p>
        <Translation>
          He lives at his grandmother&#8217;s
        </Translation>
        <p>
          Wir fahren <b>zu</b> sein<b>em</b> Ferienhaus
        </p>
        <Translation>
          We are going to his holiday home
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Prepositions that take the genitive
    </h2>
    <p>
      The prepositions which take the genitive are:
      <u>trotz, während, wegen, anstatt</u>
    </p>
    <Example>
      <Examples>
        <p>
          <b>Anstatt</b> meines Bruders kam meine Schwester
        </p>
        <Translation>
          Instead of my brother, my sister came
        </Translation>
        <p>
          <b>Trotz</b> des Regens spielten die Kinder
          draußen
        </p>
        <Translation>
          Despite the rain, the children were playing
          outside
        </Translation>
        <p>
          Er schläft immer <b>während</b> des Unterrichts
        </p>
        <Translation>
          He always sleeps during the lesson
        </Translation>
        <p>
          <b>Wegen</b> des Lärms konnte sie nicht schlafen
        </p>
        <Translation>
          Because of the noise, she couldn&#8217;t sleep
        </Translation>
        <Popup
          content="Colloquially <i>wegen</i> is often used with a dative instead of <br/>
        a genitive, and this structure has also become acceptable.<br />
        <i>Wgen dem Lärm konnte sie nicht schlafen.</i>
        (Because of the noise, she coudn&#8217;t sleep)"
        />
      </Examples>
    </Example>
    <h2 className="ui header">
      Prepositions taking the Accusative
    </h2>
    <p>
      Add the Accusative endings to the indefinite articles,
      following the pattern of the definite articles
    </p>
    <p>Masculine:</p>
    <Example>
      <Examples>
        <p>
          d<b>en</b>, ein<u>en</u>
        </p>
      </Examples>
    </Example>
    <p>Feminine:</p>
    <Example>
      <Examples>
        <p>
          d<b>ie</b>, ein<u>e</u>
        </p>
      </Examples>
    </Example>
    <p>Neuter:</p>
    <Example>
      <Examples>
        <p>
          da<b>s</b>, ein
        </p>
      </Examples>
    </Example>
    <Popup
      content="The accusative article only changes<br /> in the Masculine.
      The feminie and neuter articles stay<br /> in the same as in the nominative"
    />
    <p>
      The prepositions which take the accusative are:
      <u>durch, für, gegen, ohne, um</u>
    </p>
    <Example>
      <Examples>
        <p>
          Er geht <b>durch</b> de<b>n</b> Wald
        </p>
        <Translation>
          He goes through the forest
        </Translation>
        <p>
          Das Geschenk ist <b>für</b> meine Freundin
        </p>
        <Translation>
          The present is for my girlfriend
        </Translation>
        <p>
          <b>Ohne</b> ein<b>en</b> Schirm berließ sie das
          Haus
        </p>
        <Translation>
          Without an umbrella, she left the house
        </Translation>
        <p>
          Das Kind geht <b>um</b> da<b>s</b> Haus herum
        </p>
        <Translation>
          The child walks around the house
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">Wechselpräpositionen</h2>
    <p>
      Prepositions such as{' '}
      <i>in, an, auf, hinter, neben, unter, über</i>
      are called <i>Wechselpräpositionen</i>. They take
      either the dative or the
      <u>accusative</u>
    </p>
    <Example>
      <Examples>
        <p>
          Das Papier <b>neben dem</b> Drucker. Ich habe es{' '}
          <b>neben den</b> Kopierer gelegt
        </p>
        <Translation>
          The paper was (lit. lay) next to the printer. I
          put it next to the copy machine
        </Translation>
      </Examples>
    </Example>
    <p>
      With the <u>dative</u>, the preposition describes{' '}
      <u>where</u> something is located
    </p>
    <Example>
      <Examples>
        <p>
          Das Papier lag <b>neben dem Drucker</b>
          <Popup content="<b>Where</b> was the paper? Next to the printer" />
        </p>
        <Translation>
          The paper was (lit. lay) next to the printer
        </Translation>
      </Examples>
    </Example>
    <p>
      With the <u>accusative</u>, the preposition describes{' '}
      <u>where</u> something is (being) put or placed
    </p>
    <Example>
      <Examples>
        <p>
          Ich habe das Papier <b>neben den Drucker</b>{' '}
          gelegt
          <Popup content="<b>Where</b> did I put the paper? Next to the printer" />
        </p>
        <Translation>
          I put the paper next to the printer
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Change the case from dative to accusative
    </h2>
    <p>
      Er sitzt <b>auf dem Stuhl</b> - Er setzt sich{' '}
      <u>auf den Stuhl</u> (der Stuhl)
    </p>
    <Translation>
      He sits on the chair - He sits himlself on the chair
    </Translation>
    <p>
      Der Mantel hängt <b>an der Garderobe</b> - Er hängt
      den Mantel <u>an die Geraderobe</u> (die Garderobe)
    </p>
    <Translation>
      The jacket is hanging in the wardrobe - He hangs the
      jacket in the wardrobe
    </Translation>
    <p>
      Die Blumen stheen <b>auf dem Fensterbrett</b> - Ich
      stelle die Blumen <u>auf das Fensterbrett</u> (das
      Fensterbrett)
    </p>
    <Translation>
      The flowers are on the window sill - I put the flowers
      on the window sill
    </Translation>
    <h2 className="ui header">Review</h2>
    <p>
      The <i>Wechelpräpositionen</i> can either take the{' '}
      <u>dative</u> or the <u>accusative</u>
    </p>
    <Example>
      <Examples>
        <p>
          Das Papier lag <b>auf dem</b> Schreibtisch. Ich
          habe es <b>auf den</b> Schreibtisch gelegt
        </p>
        <Translation>
          The paper was on gthe desk. I put it on the desk
        </Translation>
      </Examples>
    </Example>
    <p>
      With the <u>dative</u>, the preposition describes{' '}
      <b>where</b> something is located
    </p>
    <Example>
      <Examples>
        <p>
          Die Firma is <b>in der</b> Stadt
        </p>
        <Translation>
          The company is in the city
        </Translation>
      </Examples>
    </Example>
    <p>
      With the <u>accusative</u>, the preposition describes{' '}
      <i>where</i> something is put or moving <b>to</b>
    </p>
    <Example>
      <Examples>
        <p>
          Ich gehe <b>in die</b> Stadt
        </p>
        <Translation>I am going into the city</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">Fusing prepositions</h2>
    <p>
      Some prepositions fuse with the definite articles.{' '}
      <i>Von</i> and <i>bei</i> fuse with the masculine and
      neuter articles in the dative as followes:
    </p>
    <p>
      <b>vo</b>n + de<b>em</b> = <b>vom</b>
    </p>
    <Example>
      <Examples>
        <p>
          Ich habe <u>vom</u> Artzt ein Rezept bekommen
        </p>
        <Translation>
          I got a prescription from the doctor
        </Translation>
      </Examples>
    </Example>
    <p>
      <b>bei</b>n + de<b>em</b> = <b>beim</b>
    </p>
    <Example>
      <Examples>
        <p>
          Ich kaufe Brot <u>beim</u> Bäcker
        </p>
        <Translation>
          I buy bread at the bakery (lit. at the baker)
        </Translation>
      </Examples>
    </Example>
    <p>
      <i>Von</i> and <i>bei</i> do <b>not</b> fuse with the
      feminine articles and stay
      <i>von der</i> and <i>bei der</i>
    </p>
    <Example>
      <Examples>
        <p>
          Ich habe <u>von der</u> Ärtzin ein Rezept bekommen
        </p>
        <Translation>
          I git a prescription from the doctor
        </Translation>
      </Examples>
    </Example>
    <Example>
      <Examples>
        <p>
          Sie was gestern <u>bei der</u> Orthopädin
        </p>
        <Translation>
          She was at her orthopaedist&#8217;s yesterday
        </Translation>
      </Examples>
    </Example>
    <p>
      <i>Zu</i> fuses in the sative with the masculine,
      neuter <b>and</b> feminine articles as follows:
    </p>
    <p>
      <b>zu</b> + de<b>m</b> = <b>zum</b>
    </p>
    <Example>
      <Examples>
        <p>
          Ich gehe <u>zum</u> Orthopädin
        </p>
        <Translation>
          I am going to the orthopaedist
        </Translation>
      </Examples>
    </Example>
    <p>
      <b>zu</b> + de<b>r</b> = <b>zur</b>
    </p>
    <Example>
      <Examples>
        <p>
          Mein Sohn geht morgen nicht <u>zur</u> Schule
        </p>
        <Translation>
          My son won&#8217;t go to school tomorrow
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">Review</h2>
    <p>
      The prepositions <i>bei, zu, mit</i> and <i>von</i>{' '}
      take the <u>dative</u>
    </p>
    <p>
      Some prepositions <i>fuse</i> with the definite
      articles
    </p>
    <p>
      The prepositions <i>bei</i> and <i>von</i> do not fuse
      with the <u>feminine</u> articles
    </p>
  </Description>
);

export default Preposition;
