// @flow
import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import CharButton from 'components/task/CharButton';
import CharKeys from 'components/task/CharKeys';
import {
  insertText,
  simplify,
  capitalize,
} from 'utils/string';
import COLORS from 'constants/colors';

const Actions = styled.div`
  margin-top: 4rem;
`;

const Answer = styled.div`
  margin: 1.2rem 0;
`;

const CharActions = styled.div`
  margin: 1.2rem 0;
`;

const Hint = styled.span`
  margin-left: 1rem !important;

  &.label {
    background-color: ${COLORS.WHITE};
    border: 0.1rem dashed ${COLORS.ZIRCON};
    color: ${COLORS.ALUMINIUM};
  }
`;

const Label = styled.span`
  margin-left: 0.5rem;
`;

const Message = styled.span`
  display: block;
  font-size: 1.6rem;
`;

const Nav = styled.nav`
  margin: 0 1rem;
`;

const Question = styled.div`
  display: inline-block;
`;

const Tips = styled.div`
  .icon {
    font-size: 1.6rem;
  }

  .message {
    display: block;
    font-size: 1.6rem;
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  font-size: 1.4rem;
  margin: 2em 0;
  padding-bottom: 2em;
  text-align: center;

  .checkmark,
  .remove {
    margin: 1rem;
  }

  .left.button {
    float: left;
  }

  .right.button {
    float: right;
  }

  .ui.tag.label {
    margin-left: 6rem;
    position: absolute;
  }
`;

const Input = styled.input`
  ${breakpoint('mobile')`
    border: ${({ isCorrect, showAnswer }) =>
      css`${
        showAnswer && !isCorrect
          ? `0.2rem solid ${COLORS.PERSIAN_ROSE}`
          : isCorrect ? `0.2rem solid ${COLORS.HARLEQUIN}` : null
      } !important`};
  `}
  ${breakpoint('tablet')`
    border: 0.2rem solid rgba(34, 36, 38, .15) !important;
  `}
`;

const Result = styled.i`
  display: none !important;
  line-height: 1;

  ${breakpoint('tablet')`
    display: inline-block !important;
  `}
`;

type Props = {
  id: string,
  isFormal: boolean,
  isPlural: boolean,
  isFirst: boolean,
  isLast: boolean,
  handleNextClick: Function,
  handlePreviousClick: Function,
  nextTask: any,
  previousTask: any,
  question: string,
  answer: string,
  hint: string,
  tips: any,
};

function Task(props: Props) {
  const {
    question,
    answer: respond,
    hint,
    isFormal,
    isPlural,
    isFirst,
    isLast,
    tips,
    handleNextClick,
    handlePreviousClick,
    nextTask,
    previousTask,
  } = props;

  const [answer, setAnswer] = useState('');
  const [isCharKeysOn, setIsCharKeysOn] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const [size, setSize] = useState(props.answer.length);
  // eslint-disable-next-line
  const [textPosition, setTextPosition] = useState(0);
  const answerRef = useRef(null);
  const submitRef = useRef(null);

  const isCorrect =
    simplify(respond) ===
    (answerRef.current &&
      simplify(answerRef.current.value));

  const handleAddChar = (char: string) => {
    insertText(char, answerRef.current);
    setAnswer(answer);
  };

  const handleAnswerChange = (evt: Event) => {
    const { value } = evt.target;
    const { answer } = props;

    evt.target.value = capitalize(value);

    setAnswer(value);
    setSize(
      value.length > answer.length
        ? value.length
        : answer.length
    );
  };

  const handleCharKeysToggle = () =>
    setIsCharKeysOn(!isCharKeysOn);

  const handleKeyDown = (evt: Event) =>
    setTextPosition(evt.target.selectionStart);

  const handleKeyPress = (evt: Event) => {
    const addChar = char => {
      evt.preventDefault();
      handleAddChar(char);
    };

    switch (evt.key) {
      case '@':
        isCharKeysOn && addChar('ä');
        break;
      case '#':
        isCharKeysOn && addChar('ö');
        break;
      case '$':
        isCharKeysOn && addChar('ü');
        break;
      case '%':
        isCharKeysOn && addChar('ß');
        break;
      case 'Enter':
        this.submitRef.current.click();
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    setShowAnswer(!showAnswer);

    if (showAnswer) {
      answerRef.current.value = '';
    }
  };

  const renderAnswer = (answer: string) => {
    return (
      showAnswer && (
        <Answer>
          <p>{answer}</p>
        </Answer>
      )
    );
  };

  const renderFeedback = (isCorrect, respond) => {
    const result = isCorrect ? 'checkmark' : 'remove';

    const renderResult = () => (
      <Result className={`${result} icon`} />
    );

    if (showAnswer && isCorrect) {
      answerRef.current.value = respond;

      return renderResult();
    }

    return (isCorrect || showAnswer) && renderResult();
  };

  const renderHint = (hint: string) => {
    if (hint) {
      return <Hint className="ui label">{hint}</Hint>;
    }
  };

  const renderHelper = (
    isFormal: boolean,
    isPlural: boolean
  ) => {
    const color = isFormal ? 'blue' : 'yellow';

    if (isPlural) {
      return (
        <Label className="ui green tag label">Plural</Label>
      );
    }

    return isFormal !== undefined ? (
      <Label className={`ui ${color} tag label`}>
        {isFormal ? 'formal' : 'informal'}
      </Label>
    ) : null;
  };

  const renderTips = tips => {
    const entries = [];

    if (tips) {
      for (const [key, value] of Object.entries(tips)) {
        entries.push([
          <Message key={key}>
            <strong>{key}:</strong> {value}
          </Message>,
        ]);
      }
    }

    return (
      tips && (
        <Tips className="ui icon message">
          <i className="idea icon" />
          <div>{entries}</div>
        </Tips>
      )
    );
  };

  return (
    <Wrapper className="ui padded">
      <Question>
        <p>
          {question}
          {renderHint(hint)}
          {renderHelper(isFormal, isPlural)}
        </p>
        <div className="ui input">
          <Input
            type="text"
            onChange={handleAnswerChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            ref={answerRef}
            size={size}
            isCorrect={isCorrect}
            showAnswer={showAnswer}
            autoCapitalize="off"
            autoComplete="off"
            spellCheck="false"
            autoCorrect="off"
          />
          {renderFeedback(isCorrect, respond)}
        </div>
        {renderAnswer(respond)}
        <CharActions>
          <CharButton
            handleAddChar={handleAddChar}
            char="ä"
          />
          <CharButton
            handleAddChar={handleAddChar}
            char="ö"
          />
          <CharButton
            handleAddChar={handleAddChar}
            char="ü"
          />
          <CharButton
            handleAddChar={handleAddChar}
            char="ß"
          />
          <CharButton
            handleAddChar={handleAddChar}
            char="Ä"
          />
          <CharButton
            handleAddChar={handleAddChar}
            char="Ö"
          />
          <CharButton
            handleAddChar={handleAddChar}
            char="Ü"
          />
        </CharActions>
        {renderTips(tips)}
        <button
          className="ui secondary button"
          onClick={handleSubmit}
          ref={submitRef}>
          {showAnswer ? 'Retry' : 'Check'}
        </button>
      </Question>
      <Actions>
        <button
          className="ui left labeled icon button"
          disabled={isFirst}
          onClick={evt =>
            handlePreviousClick(evt, previousTask)
          }>
          <i className="left arrow icon" />
          <Nav>Prev</Nav>
        </button>
        <button
          className="ui right labeled icon button"
          disabled={isLast}
          onClick={evt => handleNextClick(evt, nextTask)}>
          <i className="right arrow icon" />
          <Nav>Next</Nav>
        </button>
      </Actions>
      <CharKeys
        isCharKeysOn={isCharKeysOn}
        handleCharKeysToggle={handleCharKeysToggle}
      />
    </Wrapper>
  );
}

export default Task;
