import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toPascalCase from 'to-pascal-case';
import Aside from 'components/page/Aside';
import Tasks from 'components/page/Tasks';
import SideMenu from 'components/menu/SideMenu';
import { renderReferences } from 'containers/page/shared/References';
import {
  conversationMenuItems,
  panels,
} from 'constants/page';
import * as actions from 'actions/course';

const ConversationPage = ({
  courseName,
}: {
  courseName: string,
}) => {
  const breadcrumbLinks = [
    { name: 'Home', link: '/' },
    { name: 'Conversation', link: '/conversation' },
    {
      name: toPascalCase(courseName),
      link: `/conversation/${courseName}`,
      isActive: true,
    },
  ];

  return (
    <div className="ui grid">
      <Aside size="three">
        <SideMenu menuItems={conversationMenuItems} />
      </Aside>
      <Tasks
        courseName={courseName}
        courseType="conversation"
        breadcrumbLinks={breadcrumbLinks}
      />
      <Aside size="five" side="right">
        {renderReferences(panels)}
      </Aside>
    </div>
  );
};

export default connect(
  state => state,
  dispatch => bindActionCreators(actions, dispatch)
)(ConversationPage);
