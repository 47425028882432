import * as actions from 'constants/actions';

export function requestCourse(
  courseName: string,
  courseType: string
) {
  return {
    type: actions.REQUEST_COURSE,
    courseName,
    courseType,
  };
}

export function courseReceived(course) {
  return {
    type: actions.REQUEST_COURSE_SUCCESS,
    course,
  };
}

export function requestCourseFailed(error) {
  return {
    type: actions.REQUEST_COURSE_FAILED,
    error,
  };
}
