import React from 'react';
import Popup from 'components/shared/Popup';
import {
  Description,
  Example,
  Examples,
  Translation,
} from 'styles/content';

const Perfect = () => (
  <Description>
    <h2 className="ui header">
      What is the <i>Perfekt</i>?
    </h2>
    <p>
      The structure of the <b>Perfekt</b> is comparable to
      the <i>present perfect</i>
      in English
    </p>
    <p>
      It is formed by using an auxiliary verb <u>sein</u> or{' '}
      <u>haben</u> and the
      <b>past participle</b> of the main verb
    </p>
    <Example>
      <Examples>
        <p>
          Ich <b>bin gekommen</b>
        </p>
        <Translation>I came</Translation>
      </Examples>
      <Examples>
        <p>
          Sie <b>hat</b> Getränke <b>gekauft</b>
        </p>
        <Translation>
          She bought drinks (lit. She has bought drinks)
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      Forming the <i>perfect participle</i>?
    </h2>
    <p>
      Most verbs have a participle which is formed as
      follows: <b>ge</b> + verb stern + <b>t</b>
    </p>
    <Example>
      <Examples>
        <p>
          Ich <b>habe</b> einen Kuchen ge<b>mach</b>t
        </p>
        <Translation>I have made a cake</Translation>
      </Examples>
      <Examples>
        <p>
          Ich <b>habe</b> in Berlin geb<b>wohn</b>t
        </p>
        <Translation>I have lived in Berlin</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      The <i>Perfekt</i> irregular verbs
    </h2>
    <p>
      Some verbs such as <i>kommen</i> and <i>fahren</i> are
      irregular. These verbs form their past participle as
      follows: <b>ge-</b> + <u>infinitive</u>
    </p>
    <Example>
      <Examples>
        <p>
          Wann sind die <b>gekommen</b>?
        </p>
        <Translation>When did you come?</Translation>
      </Examples>
      <Examples>
        <p>
          Wir sind nach Italien <b>gefahren</b>?
        </p>
        <Translation>We went to Italy</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      The auxiliary verb <i>haben</i> and the <i>Perfekt</i>
    </h2>
    <p>Many verbs form the prefect with haben</p>
    <Example>
      <Examples>
        <p>
          Ich habe <b>ein UFO</b> gesehen
        </p>
        <Translation>I saw a UFO</Translation>
      </Examples>
      <Examples>
        <p>
          Er hat <b>den Kuchen</b> gegessen
        </p>
        <Translation>He ate the cake</Translation>
      </Examples>
    </Example>
    <Example>
      <Examples>
        <p>
          Hast du <b>meine Tasche</b> genommen?
        </p>
        <Translation>Did you take my bag?</Translation>
      </Examples>
      <Examples>
        <p>
          Wir haben <b>meine Schwester</b> getroffen
        </p>
        <Translation>We met my sister</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      When do we use <i>haben</i>?
    </h2>
    <p>
      Verbs of <u>position</u>, expect <i>bleiben</i>,
      always take auxiliary <b>haben</b>:
    </p>
    <Example>
      <Examples>
        <p>
          Sie haben <b>gesessen</b>
        </p>
        <Translation>They sat</Translation>
      </Examples>
      <Examples>
        <p>
          Wir haben lange <b>gestanden</b>
        </p>
        <Translation>We stood for a long time</Translation>
      </Examples>
    </Example>
    <Example>
      <Examples>
        <p>
          Wir haben in Berlin <b>gewohnt</b>
        </p>
        <Translation>We have lived in Berlin</Translation>
      </Examples>
    </Example>
    <p>
      Other verbs always use the verb <b>haben</b>:
    </p>
    <p>
      <u>Reflexive</u> verbs:
    </p>
    <Example>
      <Examples>
        <p>
          Ihr habt <b>euch</b> gewaschen
        </p>
        <Translation>You had a wash</Translation>
      </Examples>
      <Examples>
        <p>
          Du hast <b>dich</b> geirrt
        </p>
        <Translation>You made a mistake</Translation>
      </Examples>
    </Example>
    <p>
      As well as modal verbs and the verb <i>haben</i>:
    </p>
    <Example>
      <Examples>
        <p>
          Sie haben Glück <b>gehabt</b>
        </p>
        <Translation>They were lucky</Translation>
        <p>
          Ich haben immer ein Kind <b>gewollt</b>
        </p>
        <Translation>
          I have always wanted a child
        </Translation>
      </Examples>
    </Example>
    <p>
      In the prefect, the auxiliary verb <i>sein</i> is used
      with:
    </p>
    <p>
      Verbs of <u>movement</u>:
    </p>
    <Example>
      <Examples>
        <p>
          Sie <b>ist losgegangen</b>
        </p>
        <Translation>She left</Translation>
        <p>
          Sie <b>ist gefahren</b>
        </p>
        <Translation>She left</Translation>
      </Examples>
    </Example>
    <p>
      The verb <u>bleiben</u>:
    </p>
    <Example>
      <Examples>
        <p>
          Ich <b>bin</b> lange in der Schule{' '}
          <b>geblieben</b>
          <Popup
            content="Other verbs of position<br/>
          (e.g. <i>warten, sitzen, wohnen<i>), take <i>haben</i>"
          />
        </p>
        <Translation>
          I stayed at school for a long time
        </Translation>
      </Examples>
    </Example>
    <p>
      The verbs <u>change of state</u>:
    </p>
    <Example>
      <Examples>
        <p>
          Sie <b>ist</b> dort <b>aufgewachsen</b>
        </p>
        <Translation>She grew up there</Translation>
        <p>
          Sie <b>ist</b> rot <b>geworden</b>
          <Popup
            content="Other verbs of change of state are<br/>
          <i>werden, wachsen, sterben<i>, etc."
          />
        </p>
        <Translation>She blushed</Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      The perfect of irregular verbs
    </h2>
    <p>
      There is another category of irregular verbs. Their
      past participle also takes <b>ge-</b> + <b>-en</b>,
      but the verb in the stem also changes
    </p>
    <Example>
      <Examples>
        <p>
          Hast du <b>gesprochen</b>?
        </p>
        <Translation>Did you speak?</Translation>
        <p>
          Ich habe meiner Mutter <b>geholfen</b>
        </p>
        <Translation>I helped my mother</Translation>
        <p>
          Wir haben unseren alten Professor <b>getroffen</b>
        </p>
        <Translation>We met our old professor</Translation>
        <p>
          Hast du ein Stück Kuchen <b>genommen</b>?
        </p>
        <Translation>
          Did you take a pirce of cake?
        </Translation>
      </Examples>
    </Example>
    <p>
      The vowel in the stem <u>doesn&#8217;t always</u>{' '}
      change to <i>o</i>
    </p>
    <p>
      In some cases, the <b>ei</b> in the stem becomes{' '}
      <u>ie</u> in the perfect
    </p>
    <Example>
      <Examples>
        <p>
          <i>bleiben</i>: Er ist gebl<b>ie</b>ben
        </p>
        <Translation>to stay: He stayed</Translation>
        <p>
          <i>schreiben</i>: Wast hast du <u>geschrieben</u>?
        </p>
        <Translation>Did you speak?</Translation>
      </Examples>
    </Example>
    <p>
      In some cases, the <b>i</b> in the stem becomes{' '}
      <u>u</u>
    </p>
    <Example>
      <Examples>
        <p>
          <i>finden</i>: Er hat einen Schatz gef<b>u</b>nden
        </p>
        <Translation>
          to find: He found a treasure
        </Translation>
        <p>
          <i>springen</i>: Das Kaninchen ist{' '}
          <u>gesprungen</u>?
        </p>
        <Translation>
          to jump: The rabbit jumped
        </Translation>
      </Examples>
    </Example>
    <h2 className="ui header">
      The past participle of the <i>halten</i> verbs
    </h2>
    <p>
      The past participle of <i>halten</i> is{' '}
      <i>gehalten</i>. The compound verbs different
      participles, depending on if they are separable or
      not.
    </p>
    <p>
      Separable verbs: prefix + <i>halten</i>, e.g{' '}
      <i>behalten</i>
      (just same as the infinitive)
    </p>
    <p>
      Non-separable verbs: prefix + <i>ge</i> +{' '}
      <i>halten</i>, e.g <i>festgehalten</i>
    </p>
    <Example>
      <Examples>
        <p>
          fest<u>gehalten</u>?
        </p>
        <Translation>held on to</Translation>
        <p>
          be<u>halten</u>
        </p>
        <Translation>kept</Translation>
        <p>
          an<u>gehalten</u>
        </p>
        <Translation>stopped</Translation>
        <p>
          ent<u>halten</u>
        </p>
        <Translation>contained</Translation>
        <p>
          aus<u>gehalten</u>
        </p>
        <Translation>endured</Translation>
        <p>
          er<u>halten</u>
        </p>
        <Translation>received</Translation>
      </Examples>
    </Example>
  </Description>
);

export default Perfect;
